import React from "react"
import styled from "styled-components"

const YouTube = (props) => {
  const { video, ...rest } = props

  if (!video || !video.includes("youtu")) {
    return
  }

  var match = RegExp("[?&]" + "v" + "=([^&]*)").exec(video)
  const videoId = decodeURIComponent(match[1].replace(/\+/g, " "))

  return (
    <Container {...rest}>
      <Inner>
        <Embed
          src={`https://www.youtube.com/embed/` + videoId + `?rel=0`}
          frameBorder={0}
        />
      </Inner>
    </Container>
  )
}

export default YouTube

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Inner = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
`

const Embed = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

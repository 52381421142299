import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Edges, Headline, Spacer, Button } from "components"
import { colors } from "theme"

const CallToAction = props => {
  const {
    backgroundColor,
    button,
    headline,
    icon,
    pt,
    pb,
    settings,
    subHeading,
    textColor
  } = props

  const { link, ...buttonRest } = button || {}

  return (
    <Container
      backgroundcolor={backgroundColor}
      textcolor={textColor}
    >
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings.contentLayout}>
          <Flex>
            <CallToActionLeft button={button}>
              <Flex>
                {icon && (
                  <IconCont
                    backgroundcolor={icon.backgroundColor}
                  >
                    {icon.image && (
                      <img
                        alt={icon.image.altText}
                        src={icon.image.sourceUrl}
                      />
                    )}
                  </IconCont>
                )}
                <TextCont button={button}>
                  {headline.text && (
                    <Spacer mb={10}>
                      <Headline
                        className="callToAction__Headline"
                        children={headline.text}
                        color={textColor}
                        textAlign={settings?.textAlign}
                        underline={false}
                        variant={headline.variant}
                      />
                    </Spacer>
                  )}
                  <SubHeading textalign={settings?.textAlign}>
                    {subHeading && <Typography children={subHeading} />}
                  </SubHeading>
                </TextCont>
              </Flex>
            </CallToActionLeft>
            <CallToActionRight button={button}>
              {button.link !== null && (
                <ButtonCont>
                  <Button
                    {...buttonRest}
                    backgroundColor={button.backgroundColor}
                    title={button.link.title}
                    url={button.link.url}
                    type="fluid"
                    disableLightbox={true}
                    textColor={button.textColor}
                  />
                </ButtonCont>
              )}
            </CallToActionRight>
          </Flex>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled(({ backgroundcolor, textcolor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundcolor || "transparent"};
  color: ${props => props.textcolor || colors.alt};
`

const SubHeading = styled(({ textalign, ...rest }) => <div {...rest} />)`
  margin: 0 auto;
  text-align: center;

  @media(min-width: 768px) {
    text-align: ${props => props.textalign ? props.textalign : "left"};
  }
`

const Flex = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @media(min-width: 768px) {
    flex-flow: row;
    justify-content: space-between;
  }
`
const TextCont = styled(({ textAlign, button, ...rest }) => (
  <div {...rest} />
))`
  flex-basis: 100%;
  text-align: center;

  .callToAction__Headline {
    text-align: center;
  } 

  //.MuiTypography-h2 {
  //  font-size: 39px;
  //  font-weight: 400;
  //}

  @media(min-width: 768px) {
    flex-basis: ${props => props.button.link ? "80%" : "100%"};
    text-align: left;

    .callToAction__Headline {
      text-align: ${props => props.textAlign || 'left'};
    }
  }
`

const ButtonCont = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 30px 0 0;

  @media (min-width: 768px) {
    margin: 0;
  }
`

const CallToActionLeft = styled(({ button, ...rest }) => <div {...rest} />)`
  @media (min-width: 768px) {
    flex-basis: ${props => props.button.link ? "70%" : "100%"};
    padding-right: 20px;
  }

  @media (min-width: 1200px) {
    padding-right: ${props => props.button.link ? "40px" : "0"};
  }
`

const CallToActionRight = styled(({ button, ...rest }) => <div {...rest} />)`
  @media (min-width: 768px) {
    flex-basis: ${props => props.button.link ? "20%" : "0"};
  }
`

const IconCont = styled(({ backgroundcolor, ...rest }) => <div {...rest} />)`
  align-items: center;
  background-color: ${props => props.backgroundcolor};
  border-radius: 8px;
  display: flex;
  height: 95px;
  justify-content: center;
  margin-bottom: 30px;
  width: 95px;

  img {
    max-width: 100%;
  }

  @media (min-width: 768px) {
    margin: 0 20px 0 0;
  }

  @media(min-width: 1200px) {
    height: 100px;
    margin: 0 30px 0 0;
    width: 100px;
  }
`

export default CallToAction

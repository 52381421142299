import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import HamburgerMenu from "react-hamburger-menu"
import useScrollPosition from "@react-hook/window-scroll"

// import app components
import { DesktopMenu, Edges } from "components"
import { useStore } from "store"
import * as theme from "theme"
import { isBrowser } from "utils"
import Logo from "icons/logo-dark.svg"

const menuBreakpoint = 1150

const HeaderMenu = () => {
  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  const scrollY = useScrollPosition()

  const isHidden = isBrowser && scrollY > 300
  const isSticky = isBrowser && scrollY > 400

  return (
    <Container isHidden={isHidden} isSticky={isSticky} className="desktop-menu">
      <StyledEdges size="lg">
        <Grid>
          <LogoContainer>
            <Link
              to="/"
              onClick={() => dispatch({ type: "SET_MENU", payload: false })}
            >
              <Logo />
            </Link>
          </LogoContainer>
          <MenuContainer>
            <DesktopMenu />
          </MenuContainer>

          <HamburgerMenuContainer
            onClick={() => dispatch({ type: "TOGGLE_MENU" })}
          >
            <HamburgerMenu
              color={"#fff"}
              isOpen={menu}
              width={18}
              height={15}
              strokeWidth={1}
              menuClicked={() => ""}
            />
          </HamburgerMenuContainer>
        </Grid>
      </StyledEdges>
    </Container>
  )
}

export default HeaderMenu

const Container = styled.div`
  position: relative;
  z-index: 999;
  background: ${theme.colors.background.dark};
  transition: ease transform 0.2s;
  transform: translateY(0);

  ${({ isHidden }) =>
    isHidden &&
    `
      position: fixed;
      z-index: 999;
      top: -58px;
      left: 0;
      width: 100%;
    `}

  ${({ isSticky }) =>
    isSticky &&
    `
      transform: translateY(58px);
    `};
`

const Grid = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  @media (min-width: ${menuBreakpoint}px) {
    justify-content: center;
    padding: 0;
  }
`

const StyledEdges = styled(Edges)`
  @media (min-width: ${menuBreakpoint}px) {
    width: 100%;
    max-width: unset;
  }
`

const MenuContainer = styled.div`
  display: none;

  @media (min-width: ${menuBreakpoint}px) {
    display: block;
  }
`

const LogoContainer = styled.div`
  display: block;

  @media (min-width: ${menuBreakpoint}px) {
    display: none;
  }

  a {
    text-decoration: none;
  }

  svg {
    width: 80px;
    height: 80px;
  }
`

const HamburgerMenuContainer = styled.div`
  padding: 15px;
  transform: translateX(15px);
  cursor: pointer;

  @media (min-width: ${menuBreakpoint}px) {
    display: none;
  }
`

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"

import { Typography } from "@material-ui/core"

// import app components
import useMenuItems from "./useMenuItems"
import { getUrlPath } from "utils"
import * as theme from "theme"

const FooterMenu = (props) => {
  const items = useMenuItems("footer-menu")

  return (
    <Menu {...props}>
      {items &&
        items.map(({ id, url, label }) => (
          <MenuItem key={id}>
            <MenuLink to={getUrlPath(url)}>
              <Typography
                color="inherit"
                component="div"
                children={Parser(label)}
              />
            </MenuLink>
          </MenuItem>
        ))}
    </Menu>
  )
}

export default FooterMenu

const Menu = styled.div`
  margin-bottom: 28px;
  a {
    text-decoration: none;
  }
`
const MenuItem = styled.div`
  display: flex;
  position: relative;

  @media (min-width: ${theme.mui.breakpoints.values.md}px) {
    justify-content: flex-end;
  }
`

const MenuLink = styled(Link)`
  padding: 5px 0;
  color: #fff;

  &:hover {
    color: ${theme.colors.secondary};
  }
`

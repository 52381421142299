const colors = {
  primary: "#1e242d",
  secondary: "#F9303E",
  alt: "#445569",
  background: {
    light: "#394656",
    dark: "#262e39"
  },
  text: {
    primary: "#374555",
    light: "#f0f0f1",
    dark: "#999a9c"
  },
  success: "#c8fdca",
  error: "#e24141"
}

export default colors

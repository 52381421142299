import React, { useState, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import { debounce } from "lodash"
import {
  InputBase,
  CircularProgress,
  ClickAwayListener,
  Typography
} from "@material-ui/core"

import CloseIcon from "icons/close.svg"
import SearchIcon from "icons/search.svg"

// import app components
import useFetch from "utils/useFetch"
import { useStore } from "store"
import * as theme from "theme"

export const menuBreakpoint = 1024

export default props => {
  const [
    {
      appState: { scrollLock, search: active }
    },
    dispatch
  ] = useStore()

  const [search, setSearch] = useState("")
  const [searchResults, setSearchResults] = useState(null)

  const endpoint = `${process.env.GATSBY_WP}/wp-json/wp/v2/custom_search`

  const searchFieldInputRef = input => input && active && input.focus()

  const [executeSearch, { data, loading, error }] = useFetch(endpoint)

  const delayedFetch = useRef(debounce(args => executeSearch(args), 500))
    .current

  useEffect(() => {
    data && setSearchResults(JSON.parse(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    !search && setSearchResults(null)

    if (search && !scrollLock) {
      dispatch({ type: "SET_SCROLL_LOCK", payload: true })
    } else if (!search && scrollLock) {
      dispatch({ type: "SET_SCROLL_LOCK", payload: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const onChange = e => {
    setSearch(e.target.value)
    e.target.value && delayedFetch({ s: e.target.value })
  }

  const handleEscapeSearch = () => {
    setSearch("")
    setSearchResults(null)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleEscapeSearch}>
        <SearchBar active={!!search} search={search}>
          <SearchButton aria-label="Search">
            <SearchIcon />
          </SearchButton>
          <StyledTextField
            inputRef={searchFieldInputRef}
            value={search}
            onKeyDown={e =>
              e.key === "Enter"
                ? search !== "" &&
                  executeSearch({
                    s: e.target.value
                  })
                : e.key === "Escape" && handleEscapeSearch
            }
            onChange={e => onChange(e)}
            fullWidth
            placeholder="Search..."
            aria-label="Search"
          />
          {search && !loading && (
            <CloseButton onClick={handleEscapeSearch}>
              <CloseIcon fontSize="40px" />
            </CloseButton>
          )}
          {loading && (
            <CircularProgress size={18} className="loader" color="inherit" />
          )}

          <SearchModal
            {...{ searchResults }}
            open={searchResults}
            onClose={handleEscapeSearch}
            fullWidth
            maxWidth="sm"
          >
            {error && (
              <Item style={{ padding: "20px" }}>Something went wrong.</Item>
            )}

            {!loading && searchResults && (
              <>
                {searchResults.length > 0 ? (
                  <Results>
                    {searchResults.map(o => {
                      return (
                        <Item key={o.id} component="div">
                          <StyledLink to={o.link} onClick={handleEscapeSearch}>
                            {Parser(o.title)}
                          </StyledLink>
                        </Item>
                      )
                    })}
                  </Results>
                ) : (
                  <NoResult component="div">No Results</NoResult>
                )}
              </>
            )}
          </SearchModal>
        </SearchBar>
      </ClickAwayListener>
    </>
  )
}

const SearchBar = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  background: #394656;
  color: white;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;

  .loader {
    position: absolute;
    right: 10px;
  }
`

const StyledTextField = styled(InputBase)`
  color: #fff;

  input {
    ::placeholder {
      color: #fff;
      opacity: 1;
    }
  }
`

const Results = styled.div`
  max-height: 80vh;
  overflow: auto;
`

const icon = css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

const SearchButton = styled.button`
  ${icon}
  cursor: pointer;
  padding: 0 10px;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  svg {
    path {
      fill: ${theme.colors.secondary};
    }
  }
`

const CloseButton = styled.button`
  ${icon}
  cursor: pointer;
  padding: 0 10px;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  svg {
    fill: white;
  }
`

const SearchModal = styled.div`
  transition: transform 0.2s ease-in-out;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100%;
  background: ${theme.colors.primary};

  ${({ open }) =>
    open
      ? css`
          transform: scaleY(100%)
          display: block;
          opacity: 1;
          pointer-events: all;
        `
      : css`
          transform: scaleY(0%);
          opacity: 0;
          pointer-events: none;
        `}
`

const Item = styled(Typography)`
  font-size: 18px;
  font-weight: 300;
  padding: 0 20px;
  margin: 0;

  a {
    color: inherit;
  }

  &:hover {
    background: ${theme.colors.secondary};
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  text-decoration: none;
  color: #fff;
`

const NoResult = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  color: #fff;
`

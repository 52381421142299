import React from "react"

const Edges = ({ children, className = "", ...rest }) => (
  <div
    className={`container w-full max-w-screen-xl relative px-6 2xl:px-0 ${className}`}
    {...rest}
  >
    {children}
  </div>
)

export default Edges

import { createTheme } from "@material-ui/core/styles"
import colors from "./colors"

const defaultTheme = createTheme()
const { breakpoints } = defaultTheme

export default createTheme({
  shadows: [
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none"
  ],
  typography: {
    fontFamily: "Montserrat",
    h1: {
      fontWeight: 700,
      lineHeight: 1.15,
      color: colors.text.primary,
      fontSize: "34px",
      [breakpoints.up("sm")]: {
        fontSize: "40px"
      },
      [breakpoints.up("md")]: {
        fontSize: "46px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "52px"
      }
    },
    h2: {
      fontWeight: 600,
      lineHeight: 1.15,
      fontSize: "30px",
      color: colors.text.primary,
      [breakpoints.up("sm")]: {
        fontSize: "36px"
      },
      [breakpoints.up("md")]: {
        fontSize: "42px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "48px"
      }
    },
    h3: {
      lineHeight: 1.4,
      fontSize: "25px",
      fontWeight: 600,
      color: colors.text.primary,
      [breakpoints.up("sm")]: {
        fontSize: "30px"
      },
      [breakpoints.up("md")]: {
        fontSize: "35px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "30px"
      }
    },
    h4: {
      fontWeight: 600,
      lineHeight: 1.4,
      fontSize: "18px",
      color: colors.text.primary,
      [breakpoints.up("sm")]: {
        fontSize: "20px"
      },
      [breakpoints.up("md")]: {
        fontSize: "22px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "24px"
      }
    },
    h5: {
      fontWeight: 600,
      lineHeight: 1.3,
      fontSize: "14px",
      color: colors.text.primary,
      [breakpoints.up("sm")]: {
        fontSize: "16px"
      },
      [breakpoints.up("md")]: {
        fontSize: "18px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "20px"
      }
    },
    h6: {},
    subtitle1: {},
    subtitle2: {},
    body1: {
      fontSize: "18px",
      [breakpoints.up("md")]: {
        fontSize: "20px"
      },
      lineHeight: 1.5,
      fontWeight: 400
    },
    body2: {
      fontSize: "16px",
      lineHeight: 1.5,
      fontWeight: 400
    },
    caption: {},
    button: {
      textTransform: "none",
      fontSize: "20px"
    }
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "white"
    },
    secondary: {
      main: colors.secondary
    }
  },
  // https://material-ui.com/customization/themes/
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "0.81rem"
      }
    },
    MuiButton: {
      root: {
        padding: "15px 30px",
        lineHeight: "1.70"
      },
      text: {
        padding: "15px 30px"
      }
    }
  }
})

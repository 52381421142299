import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { Link } from "gatsby"

// import app components
import { BackgroundImage, Button, Edges, Headline, Spacer } from "components"
import { getIcon } from "utils"

const IconWall = props => {
  const {
    backgroundColor,
    button,
    iconsPerRow,
    headline,
    icons,
    image,
    pt,
    pb,
    settings,
    subHeading,
    textColor
  } = props

  const iconCount = icons ? icons.length : 0;
  const { link, ...buttonRest } = button || {}

  return (
    <Container
      image={image}
      backgroundcolor={backgroundColor}
      textcolor={textColor}
    >
      <Spacer pt={pt} pb={pb}>
        <Content>
          <Edges size={settings.contentLayout}>
            <div>
              {headline.text && (
                <Headline
                  children={headline.text}
                  textAlign={settings?.textAlign}
                  underline={true}
                  variant={headline.variant}
                  color="inherit"
                />
              )}
              {subHeading && (
                <SubHeading textalign={settings?.textAlign}>
                  <Typography children={subHeading} color="inherit" />
                </SubHeading>
              )}
              <Spacer mt={40} />
            </div>
            {icons && (
              <IconCont>
                {icons.map((icon, i) => {
                  if (icon.link !== null) {
                    return (
                      <IconColumn
                        key={i}
                        iconcount={iconCount}
                        iconsperrow={iconsPerRow}
                      >
                        <Link target={icon.link.target} to={icon.link.url}>
                          {icon.customIcon ? (
                            <img
                              alt={icon.customIcon.altText}
                              src={icon.customIcon.sourceUrl}
                              style={{
                                margin:
                                  settings?.textAlign === "center" && "0 auto"
                              }}
                            />
                          ) : (
                            icon.icon && <SvgCont>{getIcon(icon.icon)}</SvgCont>
                          )}
                          <Headline
                            textAlign={settings?.textAlign}
                            variant="h5"
                            children={icon.title}
                            color="inherit"
                            style={{ marginTop: "16px" }}
                          />
                        </Link>
                      </IconColumn>
                    )
                  }
                  return (
                    <IconColumn
                      key={i}
                      iconcount={iconCount}
                      iconsperrow={iconsPerRow}
                    >
                      <div>
                        {icon.customIcon ? (
                          <img
                            alt={icon.customIcon.altText}
                            src={icon.customIcon.sourceUrl}
                            style={{
                              margin:
                                settings?.textAlign === "center" && "0 auto"
                            }}
                          />
                        ) : (
                          icon.icon && <SvgCont>{getIcon(icon.icon)}</SvgCont>
                        )}
                        <Headline
                          textAlign={settings?.textAlign}
                          variant="h5"
                          children={icon.title}
                          color="inherit"
                          style={{ marginTop: "16px" }}
                        />
                      </div>
                    </IconColumn>
                  )
                })}
              </IconCont>
            )}
            {button.link !== null && (
              <ButtonCont>
                <Button
                  {...buttonRest}
                  backgroundColor={button.backgroundColor}
                  title={button.link.title}
                  url={button.link.url}
                  type="fluid"
                  textColor={button.textColor}
                />
              </ButtonCont>
            )}
          </Edges>
        </Content>
      </Spacer>
      {image && image.image && <BackgroundImage image={image} />}
    </Container>
  )
}

const Container = styled(({ image, textcolor, backgroundcolor, ...rest }) => (
  <div {...rest} />
))`
  background: ${props => props.backgroundcolor};
  color: ${props => props.textcolor};
  position: relative;
  padding: ${props => (props.image ? "30px 15px" : "0")};

  @media (min-width: 768px) {
    padding: ${props => (props.image ? "60px" : "0")};
  }
`

const Content = styled.div`
  z-index: 5;
  position: relative;
`

const SubHeading = styled(({ textalign, ...rest }) => <div {...rest} />)`
  text-align: ${props => props.textalign ? props.textalign : "left"};
  margin: 0 auto;
`

const IconCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`

const IconColumn = styled(({ iconsperrow, iconcount, ...rest }) => (
  <div {...rest} />
))`
  text-align: center;
  margin-bottom: 60px;
  flex-basis: 100%;

  @media (min-width: 768px) {
    flex-basis: ${props => `${100 / props.iconsperrow}%`};
    padding: 0 10px;
    margin-bottom: ${props => props.iconsperrow < props.iconcount ? "20px" : "0px"};
  }

  a {
    color: inherit;
  }
`

const ButtonCont = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0px;

  @media (min-width: 768px) {
    margin-top: 60px;
  }
`

const SvgCont = styled.div`
  min-height: 50px;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    min-height: 155px;
  }
`

export default IconWall

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Typography } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image";
import ShowMoreText from "react-show-more-text"

// import app components
import { Edges, Button, Headline, Spacer } from "components"

const Tiles = props => {
  const {
    backgroundColor,
    columns,
    headline,
    items,
    pt,
    pb,
    settings,
    subHeading,
    textColor,
    truncateText
  } = props

  function executeOnClick(isExpanded) {
    console.log(isExpanded);
  }

  return (
    <Container
      backgroundcolor={backgroundColor}
      textcolor={textColor}
    >
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings?.contentLayout}>
          {headline.text && (
            <Headline
              children={headline.text}
              textAlign={settings?.textAlign}
              underline={true}
              variant={headline.variant}
            />
          )}

          {subHeading && (
            <>
              <SubHeading textalign={settings?.textAlign}>
                <Typography children={subHeading} />
              </SubHeading>
              <Spacer mb={40} />
            </>
          )}

          {items && (
            <ItemCont>
              {items.map((item, i) => {
                return (
                  <ItemColumn
                    key={i}
                    columns={columns}
                    itemcount={items.length}
                  >
                    <Item backgroundColor={item.backgroundColor}>
                      {item.image.image && (
                        <ImgCont>
                          {item.button.link && (
                            <StyledLink style={{ position: 'absolute', height: '100%', width: '100%' }} to={item.button.link.url}></StyledLink>
                          )}
                          <ImgFlexed
                            alt={item.image.image.altText}
                            style={{
                              objectFit: `cover`
                            }}
                            objectFit={`cover`}
                            image={
                              item.image.image?.localFile?.childImageSharp?.gatsbyImageData
                            }
                          />
                        </ImgCont>
                      )}
                      <ItemContent>
                        <ItemText textColor={item.textColor}>
                          <Headline
                            color="inherit"
                            variant="h3"
                            children={item.title}
                          />
                          <Spacer mb={{ xs: 10 }} />
                          <ShowMoreText
                            lines={truncateText ? 5 : 0}
                            more='more'
                            less='Collapse'
                            onClick={executeOnClick}
                            expanded={false}
                            width={0}
                          >
                            {item.description}
                          </ShowMoreText>
                        </ItemText>

                        <ButtonCont>
                          {item?.button?.link && (
                            <ItemButton
                              backgroundColor={item.button.backgroundColor}
                              title={item.button.link.title}
                              textColor={item.button.textColor}
                              type="fluid"
                              url={item.button.link.url}
                            />
                          )}
                        </ButtonCont>
                      </ItemContent>
                    </Item>
                  </ItemColumn>
                );
              })}
            </ItemCont>
          )}
        </Edges>
      </Spacer>
    </Container>
  );
}

const Container = styled(({ backgroundcolor, textcolor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundcolor};
  color: ${props => props.textcolor};
  position: relative;
  width: 100%;
`

const SubHeading = styled(({ textalign, ...rest }) => <div {...rest} />)`
  display: flex;
  text-align: ${props => props.textalign};

  p {
    width: 100%;
  }
`

const ItemCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
`

const ItemColumn = styled(({ columns, itemcount, ...rest }) => <div {...rest} />)`
  align-items: stretch;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;

  @media (min-width: 768px) {
    margin-bottom: ${props => props.itemcount > props.columns ? "20px" : "0"};
    width: 50%;
  }

  @media (min-width: 1200px) {
    width: ${props =>
    props.columns === 2
      ? "50%"
      : props => (props.columns === 3 ? "33%" : "25%")};
  }
`

const ImgCont = styled.div`
  position: relative;
  width: 100%;
`

const StyledLink = styled(Link)`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const Item = styled(({ backgroundColor, textColor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundColor || "transparent"};
  border-radius: 5px;
  display: flex;
  flex-flow: column wrap;
  margin: 30px 0px 0px;
  overflow: hidden;
  padding-bottom: 20px;
  width: 100%;

  @media (min-width: 768px) {
    margin: 0px 10px;
  }
`

const ImgFlexed = styled(GatsbyImage)`
  width: 100%;
`

const ItemContent = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column wrap;
  justify-content: space-between;
  text-align: left;
`

const ButtonCont = styled.div`
  margin: 0px 20px;
`

const ItemButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`

const ItemText = styled.div`
  padding: 20px;
  color: ${props => props.textColor};
`

export default Tiles

import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { colors } from "theme"

const Headline = props => {
  const { underline, textAlign, color, ...rest } = props

  return (
    <Container underline={underline} textalign={textAlign}>
      <StyledTypography color={color} textalign={textAlign} {...rest} />
    </Container>
  )
}

const Container = styled(({ underline, textalgin, ...rest }) => (
  <div {...rest} />
))`
  ${props =>
    !!props.underline &&
    `
      position: relative;
      margin-bottom: 40px;

      &:after {
        content: "";
        position: absolute;
        bottom: -12px;
        ${
          props.textalign === "left"
            ? "left: 0;"
            : props.textalign === "right"
            ? "right: 0;"
            : "left: 50%; transform: translateX(-50%);"
        }
        
        height: 5px;
        width: 125px;
        background: ${colors.secondary};
      }
    `}
`

const StyledTypography = styled(({ textalign, underline, color, ...rest }) => (
  <Typography {...rest} />
))`
  text-align: ${props => props.textalign};
  color: ${props => props.color};
`

export default Headline

import React, { useState } from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import { Edges, Spacer, Button } from "components"
import { colors } from "theme"
import * as theme from "theme"

import Fallback from "../../components/post/fallback.jpg"

import Post from "components/post"

const News = props => {
  const { category, filter, pt, pb } = props
  const { nodes, ...rest } = usePostQuery()
  console.log(rest)

  const postsPerPage = 5

  const selected =
    filter === "all" ? filter : category[0].name ? category[0].name : "all"

  const getPosts = (p, cat) => {
    // Checks to see if there's a specified category of posts to display.

    const categoryPosts =
      cat === `all`
        ? nodes
        : nodes.filter(
            o =>
              o?.categories?.nodes &&
              o.categories.nodes.find(catObj => catObj.name === cat)
          )

    const paginatedPosts = categoryPosts.slice(
      (p - 1) * postsPerPage,
      (p - 1) * postsPerPage + postsPerPage
    )

    const count = Math.ceil(categoryPosts.length / postsPerPage)

    return { posts: paginatedPosts, count }
  }

  const [currentCategory, setCurrentCategory] = useState(selected)
  const [page, setPage] = useState(1)

  const initialValues = getPosts(page, currentCategory)

  const [pageCount, setPageCount] = useState(initialValues.count)
  const [posts, setPosts] = useState(initialValues.posts)

  const availableCategories = []

  nodes.map(o => {
    return (
      o?.categories?.nodes &&
      o.categories.nodes.map(p => {
        if (!availableCategories.includes(p.name)) {
          return availableCategories.push(p.name)
        } else {
          return null
        }
      })
    )
  })

  availableCategories.sort((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase())
  })

  const truncate = theText => {
    if (!theText) {
      return ""
    }

    return theText.length > 140 ? theText.substring(0, 130) + "..." : theText
  }

  const handleChangeCategory = s => {
    setPage(1)
    setCurrentCategory(s)

    const { posts, count } = getPosts(1, s)

    setPosts(posts)
    setPageCount(count)
  }

  const handlePageChange = (e, v) => {
    setPage(v)
    const currentPosts = getPosts(v, currentCategory)
    setPosts(currentPosts.posts)
    window.scrollTo(0, 515)
  }

  return (
    <Spacer pt={pt} pb={pb}>
      <Edges size="lg">
        <Flex>
          <NewsLeft>
            <Grid container justify="space-between">
              {posts &&
                posts.map(o => {
                  return (
                    <StyledPost
                      author={o.author.node}
                      categories={o.categories}
                      date={o.date}
                      headline={o.title}
                      image={o.acfPost.image}
                      fallbackimage={Fallback}
                      key={o.id}
                      text={truncate(o.acfPost.text)}
                      url={o.uri}
                    />
                  )
                })}

              {pageCount > postsPerPage && (
                <PaginationCont>
                  <Pagination
                    count={pageCount}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlePageChange}
                  />
                </PaginationCont>
              )}
            </Grid>
          </NewsLeft>

          <NewsRight>
            <Filter>
              <ButtonCont>
                <StyledButton
                  backgroundColor={
                    currentCategory === "all" ? colors.secondary : "#EBEBEB"
                  }
                  textColor={
                    currentCategory === "all" ? "#fff" : colors.text.primary
                  }
                  onClick={() => handleChangeCategory(`all`)}
                  title={`All`}
                  variant={currentCategory === `all` ? `contained` : `outlined`}
                />
              </ButtonCont>

              {availableCategories.map((s, i) => (
                <ButtonCont key={i}>
                  <StyledButton
                    backgroundcolor={
                      currentCategory === s ? colors.secondary : "#EBEBEB"
                    }
                    textcolor={
                      currentCategory === s ? "#fff" : colors.text.primary
                    }
                    key={i}
                    title={s}
                    onClick={() => handleChangeCategory(s)}
                  />
                </ButtonCont>
              ))}
            </Filter>
          </NewsRight>
        </Flex>
      </Edges>
    </Spacer>
  )
}

const Flex = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const NewsLeft = styled.div`
  flex-basis: 100%;

  @media (min-width: 1200px) {
    flex-basis: 75%;
  }
`

const PaginationCont = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  width: 100%;

  ul li {
    margin-right: 10px;
  }

  .MuiPaginationItem-root {
    background: #f8f8f8;
    border: 0px;
  }

  .MuiPaginationItem-root.Mui-selected {
    background: ${colors.secondary};
    border-radius: 0px;
    color: white;
  }
`

const NewsRight = styled.div`
  flex-basis: 100%;

  @media (min-width: 1200px) {
    margin-left: 30px;
    flex-basis: calc(25% - 30px);
  }
`

const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    justify-content: flex-start;
    margin-bottom: 40px;
  }
`

const ButtonCont = styled.div`
  margin-bottom: 20px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledButton = styled(({ backgroundcolor, textcolor, ...rest }) => (
  <Button {...rest} />
))`
  border: 1px solid #b1b7be !important;
  background: ${props => props.backgroundcolor};
  color: ${props => props.textcolor};
  font-size: 18px;
  line-height: 20px;
  width: 100%;

  &:hover {
    color: #fff;
    background: ${theme.colors.secondary} !important;
  }
`

const StyledPost = styled(Post)`
  margin-bottom: 40px;
  width: 100%;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
  }

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    margin-bottom: 80px;
  }
`

const usePostQuery = () => {
  const { allWpPost } = useStaticQuery(
    graphql`{
  allWpPost(limit: 5) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      currentPage
    }
    nodes {
      author {
        node {
          name
          nickname
          id
          url
        }
      }
      id
      title
      uri
      date
      categories {
        nodes {
          name
          databaseId
        }
      }
      acfPost {
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`
  )
  return allWpPost
}

export default News

import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Edges, Spacer, Headline } from "components"
import { colors } from "theme"

const Stats = props => {
  const {
    backgroundColor,
    headline,
    pb,
    pt,
    stats,
    settings,
    text,
    textColor
  } = props

  return (
    <Container
      backgroundcolor={backgroundColor}
      textalign={settings?.textAlign}
      textcolor={textColor}
    >
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings.contentLayout}>
          <div>
            {headline && (
              <Headline
                underline={true}
                textAlign={settings?.textAlign}
                variant={headline.variant}
                children={headline.text}
              />
            )}

            {text && <Typography color="inherit" children={text} />}
          </div>

          {stats && (
            <Spacer mt={30}>
              <StatsContainer>
                {stats.map((o, i) => {
                  return (
                    <Stat key={i}>
                      <StatNumber children={o.number} />
                      <Typography variant="h4" children={o.description} />
                    </Stat>
                  )
                })}
              </StatsContainer>
            </Spacer>
          )}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled(({ backgroundcolor, textalign, textcolor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundcolor || "transparent"};
  color: ${props => props.textcolor || colors.text.primary};
  text-align: ${props => props.textalign || "center"};
`

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`

const Stat = styled.div`
  width: 250px;
  padding: 10px;
`

const StatNumber = styled(Typography)`
  && {
    margin-bottom: 20px;
    font-size: 60px;
    font-weight: bold;
    color: ${colors.secondary};
  }
`

export default Stats

import React, { Fragment } from "react"
import styled, { css } from "styled-components"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import Parser from "html-react-parser"

export default function Breadcrumbs(props) {
  const { color, url } = props

  const paths =
    !!url &&
    url
      .replace(/\//g, " ")
      .trim()
      .split(" ")

  return (
    <Container color={color}>
      <Typography color="inherit" variant="body2" component="div">
        <Wrapper>
          <StyledLink to="/" children="Home" />

          {!!paths &&
            paths.map((item, index) => {
              return (
                <Fragment key={index}>
                  <Divider children={`/`} />
                  <StyledLink
                    to={"/" + paths.slice(0, index + 1).join("/")}
                    activeItem={index === paths.length - 1}
                    children={Parser(item.replace(/-/g, " "))}
                  />
                </Fragment>
              )
            })}
        </Wrapper>
      </Typography>
    </Container>
  )
}

const Container = styled.div`
  display: none;
  color: ${props => props.color};

  @media (min-width: 640px) {
    display: block;
    overflow: hidden;
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  transform: translateX(-8px);
`

const StyledLink = styled(({ activeItem, ...rest }) => <Link {...rest} />)`
  padding: 4px 8px 0;
  color: inherit;
  text-decoration: none;
  text-transform: capitalize;

  ${props =>
    props.activeItem &&
    css`
      font-weight: bold;
    `}
`

const Divider = styled(Typography)`
  && {
    color: inherit;
    transform: translateY(2px);
  }
`

import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import dateFormat from "dateformat"

// import app components
import { Edges, Headline, Spacer, Button } from "components"
import { colors } from "theme"

const ModuleTemplate = props => {
  const {
    backgroundColor,
    headline,
    latestDraw,
    nextDraw,
    previousDraw,
    pt,
    pb,
    settings,
    subHeading,
    textColor
  } = props

  function dateToUTC(date) {
    return dateFormat(date, "UTC:mmmm dS, yyyy")
  }

  function dateToUTCTime(date) {
    return dateFormat(date, "UTC:dddd, mmmm dS, yyyy HH:MM Z")
  }

  return (
    <Container backgroundColor={backgroundColor} textColor={textColor}>
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings.contentLayout}>
          {headline.text && (
            <Headline
              children={headline.text}
              color={textColor}
              textAlign={settings?.textAlign}
              underline={true}
              variant={headline.variant}
            />
          )}
          <SubHeading textalign={settings?.textAlign}>
            {subHeading && <Typography children={subHeading} />}
          </SubHeading>
          <Spacer mt={40}>
            <TileContainer>
              <Tile>
                <TileTitle>Previous Draw</TileTitle>
                <TileItem textalign="center">{previousDraw.drawDate}</TileItem>
                <Spacer mt={20} mb={20}>
                  <Crs color={colors.secondary}> {previousDraw.crs}</Crs>
                </Spacer>
                <TileItem textalign="center">
                  <span>Total Invitations:</span>
                  <span>{previousDraw.totalInvitations}</span>
                </TileItem>
                <TileItem textalign="center">
                  <span>Draw Number:</span>
                  <span>{previousDraw.drawNumber}</span>
                </TileItem>
                <TileItem textalign="center">
                  <span>Tie Breaker:</span>
                  <span>{previousDraw.tieBreakerDate}</span>
                </TileItem>
              </Tile>
              <Tile>
                <FlexItem>
                  <TileTitle textalign="left">Latest Draw</TileTitle>
                  <TileItem>{latestDraw.drawDate}</TileItem>
                </FlexItem>
                <FlexItem>
                  <Crs textalign="left" color="white">
                    {latestDraw.crs}
                  </Crs>
                </FlexItem>
                <FlexItem width="33.3%">
                  <TileItem>
                    <span>Total Invitations:</span>
                    <div>{latestDraw.totalInvitations}</div>
                  </TileItem>
                </FlexItem>
                <FlexItem width="33.3%">
                  <TileItem>
                    <span>Draw Number:</span>
                    <div>{latestDraw.drawNumber}</div>
                  </TileItem>
                </FlexItem>
                <FlexItem width="33.3%">
                  <TileItem>
                    <span>Tie Breaker:</span>
                    <div>{latestDraw.tieBreakerDate}</div>
                  </TileItem>
                </FlexItem>
                {latestDraw.buttonLink && (
                  <ButtonCont>
                    <Button {...latestDraw.buttonLink} />
                  </ButtonCont>
                )}
              </Tile>
              <Tile>
                <TileTitle>Next Draw</TileTitle>
                <TileItem textalign="center">{nextDraw.drawDate}</TileItem>
                <Crs color={colors.secondary}>{nextDraw.drawNumber}</Crs>
                <TileItem textalign="center">*Predicted</TileItem>
              </Tile>
            </TileContainer>
          </Spacer>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled(({ backgroundColor, textColor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundColor || "transparent"};
  color: ${props => props.textColor || colors.alt};
`

const SubHeading = styled(({ textalign, ...rest }) => <div {...rest} />)`
  text-align: ${props => props.textalign};
  margin: 0 auto;
`

const TileContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const Tile = styled.div`
  background: #f2f2f2;
  border-radius: 8px;
  display: flex;
  flex-basis: 100%;
  flex-flow: row wrap;
  font-size: 20px;
  justify-content: center;
  margin: 0 0 30px;
  padding: 30px;

  @media (min-width: 1200px) {
    flex-basis: calc(25% - 30px);
    margin: 0 15px 0;
  }

  :nth-of-type(1) {
    @media (min-width: 1200px) {
      margin-left: 0px;
    }
  }

  :nth-of-type(2) {
    background: ${colors.secondary};
    color: white;
    flex-basis: 100%;

    @media (min-width: 1200px) {
      flex-basis: calc(50% - 30px);
    }
  }

  :nth-of-type(3) {
    margin-right: 0px;
    margin-bottom: 0px;
  }
`

const TileTitle = styled(({ textalign, ...rest }) => <h2 {...rest} />)`
  flex-basis: 100%;
  font-size: 26px;
  margin: 0px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: ${props => props.textalign || "center"};
  }
`

const FlexItem = styled(({ width, ...rest }) => <div {...rest} />)`
  flex-basis: 100%;

  @media (min-width: 768px) {
    flex-basis: ${props => (props.width ? props.width : "50%")};
  }
`

const TileItem = styled(({ textalign, ...rest }) => <div {...rest} />)`
  flex-basis: 100%;
  text-align: center;

  span:last-child {
    display: inline-block;
    font-weight: bold;
    margin-left: 10px;
  }

  div {
    font-weight: bold;
  }

  @media (min-width: 768px) {
    text-align: ${props => props.textalign || "left"};
  }
`

const Crs = styled(({ color, textalign, ...rest }) => <div {...rest} />)`
  color: ${props => props.color || colors.text.primary};
  font-size: 86px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 86px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: ${props => props.textalign || "center"};
  }
`

const ButtonCont = styled.div`
  flex-basis: 100%;
  margin-top: 40px;

  .MuiButtonBase-root {
    border: 1px solid white;
    border-radius: 1px;
    color: white;
    width: 100%;
  }
`

export default ModuleTemplate

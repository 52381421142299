import React from "react"
import styled from "styled-components"
import { Grid, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { useInView } from "react-intersection-observer"
import MastercardIcon from "icons/mastercard.svg"
import VisaIcon from "icons/visa.svg"

// import app components
import { Edges, ScrollToTop, Socials, Spacer, Textarea } from "components"

import FooterMenu from "../menu/FooterMenu"
import * as theme from "theme"

const Footer = () => {
  const {
    themeOptions: {
      siteOptions: {
        footer: { text },
        contact: { phoneNumberCa, phoneNumberZa, email }
      }
    }
  } = useOptionsQuery()

  const [_, inView] = useInView()

  const date = new Date()
  const year = date.getFullYear()

  return (
    <div>
      <div className="bg-ca-gray-800" position="static">
        <ScrollToTop fixed={!inView} aria-hidden="true" />
        <Spacer pt={{ xs: 30 }} pb={{ xs: 40 }}>
          <Edges size="lg">
            <Grid container justifyContent="space-between">
              <StyledGrid item xs={12} md={4}>
                <Headline variant="h4" children={`About Us`} color="inherit" />
                {text && <Textarea content={text} />}
              </StyledGrid>

              <StyledGrid item xs={12} md={4} align="center">
                <Headline
                  variant="h4"
                  children={`Contact us`}
                  color="inherit"
                />

                {phoneNumberZa && (
                  <StyledLink href={`tel:${phoneNumberZa}`}>
                    <Typography>{phoneNumberZa} (ZA)</Typography>
                  </StyledLink>
                )}

                {phoneNumberCa && (
                  <StyledLink href={`tel:${phoneNumberCa}`}>
                    <Typography>{phoneNumberCa} (CA)</Typography>
                  </StyledLink>
                )}

                {email && (
                  <StyledLink href={`mailto:${email}`}>
                    <Typography>{email}</Typography>
                  </StyledLink>
                )}

                <Headline
                  variant="h4"
                  children={`Partners and Affiliates`}
                  color="inherit"
                  margin="40px 0 10px 0"
                />
                <StyledLink href="https://www.immigreat.io/" target="_blank">
                  <Typography>ImmiGreat</Typography>
                </StyledLink>
                <StyledLink href="https://scholarsdental.com" target="_blank">
                  <Typography>Scholars Dental</Typography>
                </StyledLink>
              </StyledGrid>

              <StyledGrid item xs={12} md={4} align="right">
                <Headline
                  variant="h4"
                  children={`Helpful Navigation`}
                  color="inherit"
                />
                <FooterMenu />
                <Headline
                  variant="h5"
                  children={`Secure Payments`}
                  color="inherit"
                />
                <PaymentMethods>
                  <MastercardIcon />
                  <VisaIcon />
                </PaymentMethods>
              </StyledGrid>
            </Grid>
          </Edges>
        </Spacer>
      </div>

      <FooterBottom>
        <Edges size="lg">
          <Grid container justifyContent="space-between">
            <div>
              <Typography
                color="inherit"
                children={`© All rights reserved - Canada Abroad - ${year}`}
              />
            </div>

            <Socials />
          </Grid>
        </Edges>
      </FooterBottom>
    </div>
  )
}

export default Footer;

const StyledGrid = styled(({ align, ...rest }) => <Grid {...rest} />)`
  && {
    max-width: 350px;
    color: #fff;
    margin-bottom: 40px;

    @media (min-width: ${theme.mui.breakpoints.values.md}px) {
      margin-bottom: 0;
      text-align: ${({ align }) => align || "left"}};
    }
  }
`

const Headline = styled(Typography)`
  margin: ${({ margin }) => margin} !important;
  && {
    margin-bottom: 15px;

    @media (min-width: ${theme.mui.breakpoints.values.md}px) {
      margin-bottom: 30px;
    }
  }
`

const StyledLink = styled.a`
  margin-bottom: 5px;
  color: #fff;
  text-decoration: none;

  &:hover {
    color: ${theme.colors.secondary};
  }
`

const FooterBottom = styled.div`
  && {
    padding: 20px 5px;
    background: ${theme.colors.primary};
    color: #fff;

    @media (min-width: ${theme.mui.breakpoints.values.md}px) {
      padding: 10px 0;
    }

    p {
      font-size: 12px;
      margin-bottom: 10px;

      @media (min-width: ${theme.mui.breakpoints.values.md}px) {
        margin-bottom: 0;
      }
    }
  }
`

const PaymentMethods = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;

  @media (min-width: ${theme.mui.breakpoints.values.md}px) {
    justify-content: flex-end;
  }

  svg {
    width: 30px;
    height: 16px;
  }
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          themeOptions {
            siteOptions {
              footer {
                text
              }
              contact {
                phoneNumberCa
                phoneNumberZa
                email
              }
            }
          }
        }
      }
    `
  )
  return wp
}

import React, { useState, useMemo } from "react"
import styled from "styled-components"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Typography
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

// import app components
import { Edges, Headline, Textarea, Spacer } from "components"

import { colors } from "theme"
import { getIcon } from "utils"

const ExpressEntryCalculator = props => {
  const {
    age,
    adaptability,
    education,
    firstOfficialLanguageProficiency,
    headline,
    offerOfEmployment,
    pt,
    pb,
    subHeading,
    secondOfficialLanguageProficiency,
    settings,
    textColor,
    workExperience
  } = props

  const [age1, setAge1] = useState(18)
  const [agePoints, setAgePoints] = useState(0)
  const [educationLevel, setEducationLevel] = useState(0)
  const [edLvCondText, setEdLvCondText] = useState("")
  const [firstLangProf, setFirstLangProf] = useState(0)
  const [firstLangScore, setFirstLangScore] = useState(0)
  const [firstLangEligibility, setFirstLangEligibility] = useState(
    "Ineligible to apply"
  )
  const [secondLangProf, setSecondLangProf] = useState(0)
  const [secondLangScore, setSecondLangScore] = useState(0)
  const [workEx, setWorkEx] = useState(0)
  const [workExPoints, setWorkExPoints] = useState(0)
  const [employmentValue, setEmploymentValue] = useState(0)
  const [employmentScore, setEmploymentScore] = useState(0)
  const [openedPopoverId, setOpenedPopoverId] = useState(null)
  const [adaptAnchorEl, setAdaptAnchorEl] = useState(null)
  const [adaptBoxes, setAdaptBoxes] = useState({
    checked0: false,
    checked1: false,
    checked2: false,
    checked3: false,
    checked4: false,
    checked5: false,
    checked6: false
  })
  const [adaptabilityScore, setAdaptabilityScore] = useState({
    checked0: 0,
    checked1: 0,
    checked2: 0,
    checked3: 0,
    checked4: 0,
    checked5: 0,
    checked6: 0
  })
  const [tooltipEl, setTooltipEl] = useState("")
  const [openedTooltipPopoverId, setOpenedTooltipPopoverId] = useState(0)

  function agevaluetext(value) {
    return `${value}`
  }

  function workexvaluetext(value) {
    return `${value}`
  }

  const updateEducationLevel = obj => {
    let note = obj.conditionalNote !== "" ? obj.conditionalNote : ""

    setEducationLevel(obj.educationPoints ? obj.educationPoints : 0)

    setEdLvCondText(note)
  }

  const updateFirstLangProf = event => {
    setFirstLangEligibility(
      event.target.value >= 9 ? "" : "Ineligible to apply"
    )
    setFirstLangProf(event.target.value)
    setFirstLangScore(event.target.value > 9 ? event.target.value : 0)
  }

  const updateSecondLangProf = event => {
    setSecondLangProf(event.target.value)
    setSecondLangScore(event.target.value > 9 ? event.target.value : 0)
  }

  const handleAgeChange = (event, newValue) => {
    setAge1(newValue)
    switch (true) {
      case newValue >= age.cutoffAge:
        setAgePoints(0)
        break
      case newValue >= 35:
        setAgePoints(35 + age.maximumPoints - newValue)
        break
      case newValue < 35:
        setAgePoints(age.maximumPoints)
        break
      default:
        setAgePoints(0)
    }
  }

  {
    /**
    Get's the provided range value and then sets work experience points based on the amount of years.
    Range is 1-6 years of work experience.
    @param {array} rangeValues An array of provided CPT values set at a fixed length of 4.
  */
  }
  const handleWorkExChange = (event, newValue) => {
    setWorkEx(newValue)
    switch (newValue) {
      case workExperience?.rangeValues?.[0]?.yearsMin:
        setWorkExPoints(workExperience?.rangeValues?.[0]?.pointsValue)
        break
      case workExperience?.rangeValues?.[1]?.yearsMin:
        setWorkExPoints(workExperience?.rangeValues?.[1]?.pointsValue)
        break
      case workExperience?.rangeValues?.[2]?.yearsMin:
      case workExperience?.rangeValues?.[2]?.yearsMax:
        setWorkExPoints(workExperience?.rangeValues?.[2]?.pointsValue)
        break
      case workExperience?.rangeValues?.[3]?.yearsMin:
      case workExperience?.rangeValues?.[3]?.yearsMax:
        setWorkExPoints(workExperience?.rangeValues?.[3]?.pointsValue)
        break
      case workExperience?.rangeValues?.[4]?.yearsMin:
        setWorkExPoints(workExperience?.rangeValues?.[4]?.pointsValue)
        break
      default:
        setWorkExPoints(0)
    }
  }

  const handleEmploymentValue = event => {
    setEmploymentValue(event.target.value)

    if (event.target.value === "none") {
      setEmploymentScore(0)
    } else {
      setEmploymentScore(10)
    }
  }

  // Simple function to calculate the sum of the values in an object.
  const sumValues = obj => Object.values(obj).reduce((a, b) => a + b)

  const handleAdaptInfoClick = (event, popoverId) => {
    setAdaptAnchorEl(event.currentTarget)
    setOpenedPopoverId(popoverId)
  }

  const handleAdaptInfoClose = () => {
    setAdaptAnchorEl(null)
    setOpenedPopoverId(null)
  }

  const handleTooltipClick = (event, popoverId) => {
    setTooltipEl(event.currentTarget)
    setOpenedTooltipPopoverId(popoverId)
  }

  const handleTooltipClose = () => {
    setTooltipEl(null)
    setOpenedTooltipPopoverId(null)
  }

  const adaptInfoOpen = Boolean(adaptAnchorEl)
  const adaptPopId = adaptInfoOpen ? "simple-popover" : undefined

  const handleAdaptability = event => {
    if (adaptBoxes[event.target.name] === false) {
      // If it hasn't been checked, set the value
      setAdaptabilityScore({
        ...adaptabilityScore,
        [event.target.name]: parseInt(event.target.value)
      })
    } else {
      // else reset to 0
      setAdaptabilityScore({ ...adaptabilityScore, [event.target.name]: 0 })
    }

    setAdaptBoxes({ ...adaptBoxes, [event.target.name]: event.target.checked })
  }

  const toolTip = (id, description) => {
    return (
      <>
        <AdaptInfo
          aria-describedby={id}
          onClick={e => handleTooltipClick(e, `open${id}`)}
          variant="contained"
        >
          {getIcon("Plus")}
        </AdaptInfo>
        <StyledPopover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          anchorEl={tooltipEl}
          id={id}
          open={openedTooltipPopoverId === `open${id}`}
          onClose={handleTooltipClose}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Textarea className="popover-textarea" content={description} />
        </StyledPopover>
      </>
    )
  }

  const adaptabilitySum = useMemo(() => {
    // Get the sum of the object values.
    let getSum = sumValues(adaptabilityScore)
    // The maximum score is 10, so it doesn't stack past that. Otherwise, stack it.
    if (getSum >= 10) {
      getSum = 10
    }
    return getSum
  }, [adaptabilityScore])

  // Calculate all values and return them as a variable
  const calculatedScore = useMemo(() => {
    return (
      educationLevel +
      firstLangScore +
      secondLangScore +
      agePoints +
      workExPoints +
      employmentScore +
      adaptabilitySum
    )
  }, [
    educationLevel,
    firstLangScore,
    secondLangScore,
    agePoints,
    workExPoints,
    employmentScore,
    adaptabilitySum
  ])

  return (
    <Container>
      <Spacer pt={pt} pb={pb}>
        <Edges size="lg">
          {headline.text && (
            <Headline
              children={headline.text}
              color={textColor}
              textAlign={settings?.textAlign}
              underline={true}
              variant={headline.variant}
            />
          )}
          {subHeading && (
            <SubHeading textalign={settings?.textAlign}>
              <Typography children={subHeading} />
            </SubHeading>
          )}
          <FormStyleCont>
            <FormContainer>
              <FormFieldCont>
                <FormColumn>
                  <InputLabel id="age-select-helper-label">
                    <Flex>
                      <div>Age: {age1 >= 50 ? age1 + "+" : age1} years old</div>
                      {age.addTooltip && (
                        <>
                          <Spacer ml={10} />
                          <div>
                            {toolTip("tt-age-select", age.tooltipDescription)}
                          </div>
                        </>
                      )}
                    </Flex>
                  </InputLabel>
                  <StyledSlider
                    defaultValue={18}
                    getAriaValueText={agevaluetext}
                    aria-labelledby="age-select-helper-label"
                    valueLabelDisplay="auto"
                    onChange={handleAgeChange}
                    step={1}
                    min={18}
                    max={50}
                  />
                  <FormHelperText>{age.helpText}</FormHelperText>
                </FormColumn>
                <FormColumn>
                  <PointsList>
                    <li>{age.pointsText}</li>
                    <li className="express-form-points">{agePoints}</li>
                  </PointsList>
                </FormColumn>
              </FormFieldCont>
              <FormFieldCont>
                <FormColumn>
                  <FormControl>
                    <InputLabel id="education-select-helper-label">
                      <Flex>
                        <div>Education</div>
                        {education.addTooltip && (
                          <>
                            <Spacer ml={10} />
                            <div>
                              {toolTip("tt-edu-select", age.tooltipDescription)}
                            </div>
                          </>
                        )}
                      </Flex>
                    </InputLabel>
                    <Select
                      labelId="education-select-helper-label"
                      id="education-select-helper"
                      value={educationLevel}
                    >
                      <MenuItem
                        value={0}
                        onClick={() => updateEducationLevel({})}
                      >
                        <em>No Education</em>
                      </MenuItem>
                      {education.educationLevels &&
                        education.educationLevels.map((o, i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={o.educationPoints}
                              onClick={() => updateEducationLevel(o)}
                            >
                              {o?.educationDescription}
                            </MenuItem>
                          )
                        })}
                    </Select>
                    <FormHelperText>{education.helpText}</FormHelperText>
                    <FormHelperText>{edLvCondText}</FormHelperText>
                  </FormControl>
                </FormColumn>
                <FormColumn>
                  <PointsList>
                    <li>{education.pointsText}</li>
                    <li className="express-form-points">{educationLevel}</li>
                  </PointsList>
                </FormColumn>
              </FormFieldCont>
              <FormFieldCont>
                <FormColumn>
                  <FormControl>
                    <InputLabel id="first-lang-select-helper-label">
                      <Flex>
                        <div>
                          First official language proficiency (French or
                          English)
                        </div>
                        {firstOfficialLanguageProficiency.addTooltip && (
                          <>
                            <Spacer ml={10} />
                            <div>
                              {toolTip(
                                "tt-1stlang-select",
                                firstOfficialLanguageProficiency.tooltipDescription
                              )}
                            </div>
                          </>
                        )}
                      </Flex>
                    </InputLabel>
                    <Select
                      labelId="first-lang-select-helper-label"
                      id="first-lang-select-helper"
                      value={firstLangProf}
                      onChange={updateFirstLangProf}
                    >
                      <MenuItem value={0} onClick={updateFirstLangProf}>
                        <em>Non-User</em>
                      </MenuItem>
                      {firstOfficialLanguageProficiency.proficiencyLevels &&
                        firstOfficialLanguageProficiency.proficiencyLevels.map(
                          (o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={
                                  o.proficiencyPoints > 9
                                    ? o.proficiencyPoints
                                    : 9 - i
                                }
                              >
                                {o.proficiencyDescription}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select>
                    <FormHelperText>
                      {firstOfficialLanguageProficiency.helpText}
                    </FormHelperText>
                  </FormControl>
                </FormColumn>
                <FormColumn>
                  <PointsList>
                    <li>{firstOfficialLanguageProficiency.pointsText}</li>
                    <li className="express-form-points">{firstLangScore}</li>
                    <li className="field-ineligible">{firstLangEligibility}</li>
                  </PointsList>
                </FormColumn>
              </FormFieldCont>
              <FormFieldCont>
                <FormColumn>
                  <FormControl>
                    <InputLabel id="second-lang-select-helper-label">
                      <Flex>
                        <div>Second official lang prof (French or English)</div>
                        {secondOfficialLanguageProficiency.addTooltip && (
                          <>
                            <Spacer ml={10} />
                            <div>
                              {toolTip(
                                "tt-2ndlang-select",
                                secondOfficialLanguageProficiency.tooltipDescription
                              )}
                            </div>
                          </>
                        )}
                      </Flex>
                    </InputLabel>
                    <Select
                      labelId="second-lang-select-helper-label"
                      id="second-lang-select-helper"
                      value={secondLangProf}
                      onChange={updateSecondLangProf}
                    >
                      <MenuItem value={0} onClick={updateSecondLangProf}>
                        <em>Non-User</em>
                      </MenuItem>
                      {secondOfficialLanguageProficiency.secondProficiencyLevels &&
                        secondOfficialLanguageProficiency.secondProficiencyLevels.map(
                          (o, i) => {
                            return (
                              <MenuItem
                                key={i}
                                value={
                                  o.proficiencyPoints > 9
                                    ? o.proficiencyPoints
                                    : 9 - i
                                }
                              >
                                {o.proficiencyDescription}
                              </MenuItem>
                            )
                          }
                        )}
                    </Select>
                    <FormHelperText>
                      {secondOfficialLanguageProficiency.helpText}
                    </FormHelperText>
                  </FormControl>
                </FormColumn>
                <FormColumn>
                  <PointsList>
                    <li>{secondOfficialLanguageProficiency.pointsText}</li>
                    <li className="express-form-points">{secondLangScore}</li>
                  </PointsList>
                </FormColumn>
              </FormFieldCont>
              <FormFieldCont>
                <FormColumn>
                  <InputLabel id="work-experience-slider">
                    <Flex>
                      <div>Work Experience</div>
                      {workExperience.addTooltip && (
                        <>
                          <Spacer ml={10} />
                          <div>
                            {toolTip(
                              "tt-workexp-select",
                              workExperience.tooltipDescription
                            )}
                          </div>
                        </>
                      )}
                    </Flex>
                  </InputLabel>
                  <StyledSlider
                    defaultValue={0}
                    getAriaValueText={workexvaluetext}
                    aria-labelledby="work-experience-slider"
                    valueLabelDisplay="auto"
                    onChange={handleWorkExChange}
                    step={1}
                    min={0}
                    max={6}
                  />
                  <FormHelperText>{workExperience.helpText}</FormHelperText>
                </FormColumn>
                <FormColumn>
                  <PointsList>
                    <li>{workExperience.pointsText}</li>
                    <li className="express-form-points">{workExPoints}</li>
                    <li className="field-ineligible">
                      {workExPoints <= 0 ? "Ineligible to apply" : ""}
                    </li>
                  </PointsList>
                </FormColumn>
              </FormFieldCont>
              <FormFieldCont>
                <FormColumn>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <StyledInputLabel id="offer-employment-label">
                        <Flex>
                          <div>
                            Offer of employment – Please select the offer that
                            applies to you, if applicable
                          </div>
                          {offerOfEmployment.addTooltip && (
                            <>
                              <Spacer ml={10} />
                              <div>
                                {toolTip(
                                  "tt-edu-select",
                                  offerOfEmployment.tooltipDescription
                                )}
                              </div>
                            </>
                          )}
                        </Flex>
                      </StyledInputLabel>
                    </AccordionSummary>
                    <AccordionDetails>
                      <RadioGroup
                        aria-label="Offer of employment"
                        aria-labelledby="offer-employment-label"
                        name="employment-offer"
                        value={employmentValue}
                        onChange={handleEmploymentValue}
                      >
                        <RadioGroupCont>
                          <FormControlLabel
                            value="none"
                            control={<Radio />}
                            label="None of these apply to me"
                          />
                        </RadioGroupCont>
                        {offerOfEmployment.offerType &&
                          offerOfEmployment.offerType.map((o, i) => {
                            return (
                              <RadioGroupCont key={i}>
                                <FormControlLabel
                                  value={`key${i}`}
                                  control={<Radio />}
                                  label={o.offerTitle}
                                />
                                <EmploymentOfferDesc>
                                  <Textarea content={o.offerDescription} />
                                </EmploymentOfferDesc>
                              </RadioGroupCont>
                            )
                          })}
                      </RadioGroup>
                    </AccordionDetails>
                  </Accordion>
                </FormColumn>
                <FormColumn>
                  <PointsList>
                    <li>Calculated Points for Employment Offer</li>
                    <li className="express-form-points">{employmentScore}</li>
                  </PointsList>
                </FormColumn>
              </FormFieldCont>
              <FormFieldCont>
                <FormColumn>
                  <CheckboxGroup>
                    {adaptability.adaptabilityType &&
                      adaptability.adaptabilityType.map((o, i) => {
                        return (
                          <FormLabelCont>
                            <FormControlLabel
                              key={i}
                              control={
                                <Checkbox
                                  checked={adaptBoxes[`checked${i}`]}
                                  onChange={handleAdaptability}
                                  name={`checked${i}`}
                                  color="primary"
                                  value={o.points}
                                />
                              }
                              label={o.title}
                            />
                            <AdaptInfo
                              aria-describedby={adaptPopId}
                              onClick={e => handleAdaptInfoClick(e, `open${i}`)}
                              variant="contained"
                            >
                              {getIcon("Plus")}
                            </AdaptInfo>
                            <StyledPopover
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                              }}
                              anchorEl={adaptAnchorEl}
                              id={adaptPopId}
                              open={openedPopoverId === `open${i}`}
                              onClose={handleAdaptInfoClose}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center"
                              }}
                            >
                              <Textarea
                                className="popover-textarea"
                                content={o.description}
                              />
                            </StyledPopover>
                          </FormLabelCont>
                        )
                      })}
                  </CheckboxGroup>
                </FormColumn>
                <FormColumn>
                  <PointsList>
                    <li>Calculated Points for adaptability</li>
                    <li className="express-form-points">{adaptabilitySum}</li>
                  </PointsList>
                </FormColumn>
              </FormFieldCont>
            </FormContainer>
            <ScoreCont>
              <div>Your Score - </div>
              <div className="express-form-score">
                {" "}
                {calculatedScore} Points
              </div>
            </ScoreCont>
            <ScoreMessage>
              {calculatedScore > 67 ? "You are eligible" : "You don't qualify"}
            </ScoreMessage>
          </FormStyleCont>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div``

const SubHeading = styled(({ textalign, ...rest }) => <div {...rest} />)`
  text-align: ${props => props.textalign || "left"};
`

const Flex = styled.div`
  display: flex;
`

const FormStyleCont = styled.div`
  margin-top: 40px;
  padding: 30px 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.22);

  @media (min-width: 768px) {
    padding: 60px 30px;
  }

  .MuiFormControlLabel-root {
    margin-right: 40px;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  label + .MuiInput-formControl {
    margin-top: 5px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormLabel-root {
    color: #2a2a2a;
    font-size: 20px;
    font-weight: 600;
    position: relative;
    transform: translate(0, -2.5px) scale(1);
  }

  .MuiFormHelperText-root {
    color: #2a2a2a;
  }

  .MuiAccordionSummary-root {
    background: #e5e7e9;
    font-weight: 600;
  }

  .MuiAccordionDetails-root {
    border: 1px solid #e5e7e9;
  }

  .field-ineligible {
    color: red;
    font-weight: bold;
  }
`

const FormFieldCont = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-flow: row wrap;
  margin-bottom: 60px;
  max-width: 100%;
`

const FormColumn = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  padding: 0px 5px;
  position: relative;

  &:nth-of-type(2n + 1) {
    margin-bottom: 15px;
  }

  @media (min-width: 1200px) {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 0 0 15px;
    margin-bottom: 0px;

    &:nth-of-type(2n + 1) {
      padding: 0 15px 0 0;
    }
  }
`

const StyledSlider = styled(Slider)`
  .MuiSlider-thumb,
  .MuiSlider-track {
    color: ${colors.secondary};
  }

  .MuiSlider-thumb {
    height: 20px;
    width: 20px;
    margin-top: -9px;
  }

  .MuiSlider-valueLabel {
    margin-top: -2px;
    font-size: 18px;
    left: calc(-50% + 3px);
  }
`

const RadioGroupCont = styled.div`
  margin-bottom: 30px;
`

const PointsList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 5px 0 0;
  font-size: 18px;

  li {
    padding: 0px;
  }

  li.express-form-points {
    color: ${colors.secondary};
    border-bottom: 1px solid #b3b4b7;
  }
`
const EmploymentOfferDesc = styled.div`
  ul {
    margin: 5px 0 0 50px !important;
  }

  ul li {
    font-size: 16px !important;
  }

  @media (min-width: 768px) {
    font-size: 18px !important;
  }
`

const StyledInputLabel = styled(InputLabel)`
  cursor: pointer;
`

const CheckboxGroup = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const ScoreCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  font-size: 46px;
  font-weight: 600;

  .express-form-score {
    margin-left: 10px;
    color: ${colors.secondary};
  }
`

const FormLabelCont = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  margin-bottom: 20px;

  .MuiFormControlLabel-label {
    line-height: 22px;
  }
`

const AdaptInfo = styled.div`
  align-items: center;
  background: ${colors.secondary};
  border-radius: 5px;
  color: white;
  display: flex;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: 0px;
  width: 20px;
`

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    background: ${colors.background.dark};
    border-radius: 10px;
    color: white;
    padding: 15px 15px;

    .popover-textarea {
      ul li,
      ol li {
        font-size: 14px;
      }

      p {
        font-size: 16px;
      }

      ul,
      ol {
        padding-left: 30px;
        margin: 5px 0px 30px 15px;
      }
    }

    @media (min-width: 768px) {
      max-width: 600px;
    }
  }
`

const ScoreMessage = styled.div`
  border-top: 1px solid #ddd;
  padding: 20px 0px 0px;
`

export default ExpressEntryCalculator

import React from "react"
import styled from "styled-components"
import CookieConsent from "react-cookie-consent"
import { Typography } from "@material-ui/core"

// import app components
import { Header, Footer, LightBox, Button } from "components"
import { colors } from "theme"

export default props => {
  return (
    <>
      <Container>
        <Header />
        <Main>{props?.children}</Main>
        <Footer />
      </Container>

      <LightBox />

      <CookieConsent
        debug={false}
        buttonText="Ok"
        cookieName="canadaAbroadCookie"
        style={{
          padding: "10px 20px",
          background: colors.primary
        }}
        buttonClasses="cookieButton"
        buttonWrapperClasses="cookieButtonContainer"
        contentClasses="cookieContent"
        expires={150}
      >
        <Typography children="This website uses cookies to enhance the user experience." />

        <Button
          style={{ marginLeft: "15px" }}
          url="/privacy-policy/"
          title="Privacy Policy"
          type="outlined"
          className="cookieButton"
        />
      </CookieConsent>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-height: 100vh;
`

const Main = styled.main``

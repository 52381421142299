import React from "react"
import styled from "styled-components"

// import app components
import { Edges, BackgroundImage, Textarea, Spacer } from "components"

const TextBlock = props => {
  const {
    backgroundColor,
    columns,
    content,
    contentAlignment,
    image,
    pt,
    pb,
    textColor,
    settings
  } = props

  return (
    <Container
      backgroundcolor={backgroundColor}
      textcolor={textColor}
    >
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings?.contentLayout}>
          <ContentContainer
            contentalignment={contentAlignment}
            contentlayout={settings?.contentLayout}
            textColor={textColor}
            textalign={settings.textAlign}
          >
            <TextColumn
              contentlayout={settings?.contentLayout}
              contentalignment={contentAlignment}
              columns={columns}
              image={image}
            >
              {content && <Textarea content={content} />}
            </TextColumn>
          </ContentContainer>
        </Edges>
      </Spacer>
      {image && image.image && <BackgroundImage image={image} />}
    </Container>
  )
}

const Container = styled(({ image, textcolor, backgroundcolor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundcolor || "transparent"};
  color: ${props => props.textcolor};
  position: relative;
`

const ContentContainer = styled(
  ({ textcolor, contentlayout, contentalignment, textalign, ...rest }) => (
    <div {...rest} />
  )
)`
  color: ${props => props.textcolor};
  display: flex;
  flex-direction: column;
  text-align: ${props => props.textalign || "left"};

  @media (min-width: 768px) {  
    flex-direction: row;
    justify-content: ${props =>
      props.contentalignment === "boxRight" ? "flex-end" : "flex-start"};
  }
`

const TextColumn = styled(
  ({ columns, contentlayout, contentalignment, ...rest }) => <div {...rest} />
)`
  background: ${props =>
    props.image.image !== null ? "rgba(29,36,44,0.85)" : "transparent"};
  border-radius: 10px;
  padding: ${props => (props.image.image !== null ? "30px" : "0px")};
  position: relative;
  z-index: 5;
  margin: ${props => (props.image.image !== null ? "40px 0px" : "0px")};

  @media (min-width: 768px) {
    margin: ${props => (props.image.image !== null ? "60px 0px" : "0px")};
    width: ${props =>
      props.contentalignment === "boxRight"
        ? "50%"
        : props => (props.contentalignment === "boxLeft" ? "50%" : "100%")};
  }

  @media (min-width: 1200px) {
    column-count: ${props => props.columns};
  }
`

export default TextBlock

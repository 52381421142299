import React from "react"
import styled, { css } from "styled-components"
import { useScrollYPosition } from "react-use-scroll-position"

import useWindowSize from "../../utils/useWindowSize"

import Chevron from "../../icons/chevron-down.svg"

export default function ScrollToTop(props) {
  const scrollY = useScrollYPosition()
  const { height } = useWindowSize()
  const scrollPercentage = Math.floor((scrollY / height) * 100)

  return (
    <Container active={scrollPercentage > 20} {...props}>
      <ToTop href="#top">
        <IconCont>
          <Chevron className="icon-up" />
        </IconCont>
      </ToTop>
    </Container>
  )
}

const Container = styled.div`
  border-radius: 50%;
  display: block;
  height: 40px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out;
  width: 40px;
  z-index: 99;
  ${props =>
    props.active &&
    css`
      pointer-events: all;
      opacity: 1;
    `}
  ${props =>
    props.fixed
      ? css`
          position: fixed;
          bottom: 90px;
          right: 20px;
        `
      : css`
          position: absolute;
          top: -110px;
          right: 20px;
        `}
`

const ToTop = styled.a`
  align-items: center;
  background: #2c312c;
  border-radius: 50%;
  border: 1px solid #2c312c;
  display: flex;
  height: 100%;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background: white;
    svg {
      path {
        fill: #2c312c;
      }
    }
  }
`

const IconCont = styled.div`
  display: flex;
  transform: rotate(180deg);
`
import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import { MuiThemeProvider } from "@material-ui/core/styles"
import Calculator from "icons/calculator.svg"
import CssBaseline from "@material-ui/core/CssBaseline"
import "core-js/es6/number"
import "core-js/es6/array"

import "../../theme/styles.css"

// import app components
import Layout from "./Layout"
import { SEO, ImmiGreatModal, Modal } from "components"
import { mui, GlobalStyles } from "theme"
import { StoreProvider } from "store"
import Form from "components/form"

export default (props) => {
  const { pageContext } = props
  const [modalOpen, setModalOpen] = React.useState(false)
  const [enquireModalOpen, setEnquireModalOpen] = React.useState(false)
  const handleCalculateClick = () => {
    setModalOpen(!modalOpen); // Opens the modal
  };
  return (
    <>
      <StoreProvider>
        <MuiThemeProvider theme={mui}>
          <Helmet>
            <html lang="en" />
            <meta name="description" />
          </Helmet>

          <SEO {...pageContext} />

          <CssBaseline />
          <GlobalStyles />

          <Layout {...props}>
            {props?.children}
            <ImmiGreatModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
            <Modal isOpen={enquireModalOpen} onClose={() => setEnquireModalOpen(false)}>
              <Form
                formId="ed98e29a-7469-4f6e-aff3-99a6a4da3950"
                formType="hubspot-legacy"
                className="w-full"
              />
            </Modal>
          </Layout>
        </MuiThemeProvider>
      </StoreProvider>
      <ContactForm
        style={{
          position: "fixed",
          zIndex: 1299,
          top: "calc(50% - 250px)",
          right: 0,
          transition: "width 300ms ease-oauth",
          width: 0
        }}
        data-qa="side_panel"
      >
        <div
          className="typeform-share button"
          onClick={handleCalculateClick} // Event handler to open modal
          data-mode="side_panel"
          style={{
            boxSizing: "border-box",
            position: "absolute",
            top: "200px",
            width: "200px",
            height: "48px",
            padding: "0 20px",
            margin: 0,
            cursor: "pointer",
            background: "#F20027",
            borderRadius: "4px 4px 0px 0px",
            boxShadow:
              "0px 2px 12px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.08)",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            transform: "rotate(-90deg)",
            transformOrigin: "bottom left",
            color: "white",
            textDecoration: "none",
            zIndex: 9999
          }}
          data-submit-close-delay="3"
          data-width="320"
          data-height="500"
          target="_blank"
        >
          <span
            className="icon"
            style={{
              width: "32px",
              position: "relative",
              textAlign: "center",
              transform: "rotate(90deg) scale(0.85)",
              left: "-8px"
            }}
          >
            <svg
              width="27" height="31" viewBox="0 0 27 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: "10px" }}
            >
              <path
                d="M4.6875 27H21.5625C22.0312 27 22.5 26.5898 22.5 26.0625V14.8125C22.5 14.3438 22.0312 13.875 21.5625 13.875H4.6875C4.16016 13.875 3.75 14.3438 3.75 14.8125V26.0625C3.75 26.5898 4.16016 27 4.6875 27ZM16.875 15.75H20.625V25.125H16.875V15.75ZM11.25 15.75H15V19.5H11.25V15.75ZM11.25 21.375H15V25.125H11.25V21.375ZM5.625 15.75H9.375V19.5H5.625V15.75ZM5.625 21.375H9.375V25.125H5.625V21.375ZM23.4375 0.75H2.8125C1.28906 0.75 0 2.09766 0 3.5625V27.9375C0 29.4609 1.28906 30.75 2.8125 30.75H23.4375C24.9023 30.75 26.25 29.4609 26.25 27.9375V3.5625C26.25 2.09766 24.9023 0.75 23.4375 0.75ZM24.375 27.9375C24.375 28.4062 23.8477 28.875 23.4375 28.875H2.8125C2.34375 28.875 1.875 28.4062 1.875 27.9375V12H24.375V27.9375ZM24.375 10.125H1.875V3.5625C1.875 3.15234 2.34375 2.625 2.8125 2.625H23.4375C23.8477 2.625 24.375 3.15234 24.375 3.5625V10.125Z"
                fill="white" />
            </svg>
          </span>
          <span
            style={{
              textDecoration: "none",
              fontSize: "18px",
              fontFamily: "Helvetica,Arial,sans-serif",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              textAlign: "center",
              WebkitFontSmoothing: "antialiased",
              MozOsxFontSmoothing: "grayscale"
            }}
          >
            Tools
          </span>
        </div>
      </ContactForm>
      <ContactForm
        style={{
          position: "fixed",
          zIndex: 1299,
          top: "calc(50% - 250px)",
          right: 0,
          transition: "width 300ms ease-oauth",
          width: 0
        }}
        data-qa="side_panel"
      >
        <div
          className="typeform-share button"
          onClick={() => {setEnquireModalOpen(!enquireModalOpen)}}
          data-mode="side_panel"
          style={{
            boxSizing: "border-box",
            position: "absolute",
            top: "405px",
            width: "200px",
            height: "48px",
            padding: "0 20px",
            margin: 0,
            cursor: "pointer",
            background: "#F20027",
            borderRadius: "4px 4px 0px 0px",
            boxShadow:
              "0px 2px 12px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.08)",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            transform: "rotate(-90deg)",
            transformOrigin: "bottom left",
            color: "white",
            textDecoration: "none",
            zIndex: 9999
          }}
          data-submit-close-delay="3"
          data-width="320"
          data-height="500"
          target="_blank"
        >
          <span
            className="icon"
            style={{
              width: "32px",
              position: "relative",
              textAlign: "center",
              transform: "rotate(90deg) scale(0.85)",
              left: "-8px"
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginTop: "10px" }}
            >
              <path
                d="M21 0H0V9L10.5743 24V16.5H21C22.6567 16.5 24 15.1567 24 13.5V3C24 1.34325 22.6567 0 21 0ZM7.5 9.75C6.672 9.75 6 9.07875 6 8.25C6 7.42125 6.672 6.75 7.5 6.75C8.328 6.75 9 7.42125 9 8.25C9 9.07875 8.328 9.75 7.5 9.75ZM12.75 9.75C11.922 9.75 11.25 9.07875 11.25 8.25C11.25 7.42125 11.922 6.75 12.75 6.75C13.578 6.75 14.25 7.42125 14.25 8.25C14.25 9.07875 13.578 9.75 12.75 9.75ZM18 9.75C17.172 9.75 16.5 9.07875 16.5 8.25C16.5 7.42125 17.172 6.75 18 6.75C18.828 6.75 19.5 7.42125 19.5 8.25C19.5 9.07875 18.828 9.75 18 9.75Z"
                fill="white"
              />
            </svg>
          </span>
          <span
            style={{
              textDecoration: "none",
              fontSize: "18px",
              fontFamily: "Helvetica,Arial,sans-serif",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              textAlign: "center",
              WebkitFontSmoothing: "antialiased",
              MozOsxFontSmoothing: "grayscale"
            }}
          >
            Enquire Now
          </span>
        </div>
      </ContactForm>
    </>
  )
}

const ContactForm = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`

import React from "react"
import styled from "styled-components"
import { Grid, Typography } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import {
  BackgroundImage,
  Button,
  Edges,
  Headline,
  Spacer,
  Textarea,
} from "components"
import { colors } from "theme"

const TextImage = props => {
  const {
    alignmentRatio,
    backgroundColor,
    button: { link, ...buttonRest },
    image,
    headline,
    pt,
    pb,
    settings,
    subHeading,
    text,
    textColor,
    imagePlacement
  } = props

  return (
    <Container
      backgroundcolor={backgroundColor}
      textcolor={textColor}
    >
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings.contentLayout}>
          {headline?.text && (
            <Spacer mb={60}>
              <Headline
                children={Parser(headline.text || "")}
                color={textColor}
                textAlign={settings?.textAlign}
                underline={true}
                variant={headline.variant}
              />
            </Spacer>
          )}
          {subHeading && (
            <SubHeading textalign={settings?.textAlign}>
              <Spacer mb={30}>
                <Typography children={subHeading} />
              </Spacer>
            </SubHeading>
          )}
          <Grid container justifyContent="space-between">
            <ImageContainer
              alignmentratio={alignmentRatio}
              imagePlacement={imagePlacement}
            >
              {image && image.image && <BackgroundImage image={image} />}
            </ImageContainer>

            <ContentContainer
              alignmentratio={alignmentRatio}
              imagePlacement={imagePlacement}
              textColor={textColor}>
              {text && <Textarea content={text} />}

              {link && (
                <Spacer mt={40}>
                  <Button {...link} {...buttonRest} />
                </Spacer>
              )}
            </ContentContainer>
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled(({ backgroundcolor, textcolor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundcolor || "transparent"};
  color: ${props => props.textcolor || colors.text.primary};
`

const ImageContainer = styled(({ imagePlacement, alignmentratio, ...rest }) => (
  <div {...rest} />
))`
  height: 300px;
  margin-bottom: 20px;
  order: 1;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    height: initial;
    margin-bottom: 0;
    order: ${props => (props.imagePlacement === "left" ? 1 : 3)};
    width: calc(50% - 20px);
    width: calc(${props => (
      props.imagePlacement === "left" &&
      props.alignmentratio === "3070" ? "30%" :
      props.imagePlacement === "left" &&
      props.alignmentratio === "7030" ? "70%" :
      props.imagePlacement === "right" &&
      props.alignmentratio === "3070" ? "70%" :
      props.imagePlacement === "right" &&
      props.alignmentratio === "7030" ? "30%" :
      "50%"
    )} - 20px);
  }

  img {
    border-radius: 12px;
  }
`

const ContentContainer = styled(({ textColor, alignmentratio, imagePlacement, ...rest }) => <div {...rest} />)`
  color: ${props => props.textColor || "inherit"};
  order: 2;

  @media (min-width: 768px) {
    width: calc(${props => (
      props.imagePlacement === "left" &&
      props.alignmentratio === "3070" ? "70%" :
      props.imagePlacement === "left" &&
      props.alignmentratio === "7030" ? "30%" :
      props.imagePlacement === "right" &&
      props.alignmentratio === "3070" ? "30%" :
      props.imagePlacement === "right" &&
      props.alignmentratio === "7030" ? "70%" :
      "50%"
    )} - 20px);
  }
`

const SubHeading = styled(({ textalign, ...rest }) => <div {...rest} />)`
  text-align: ${props => props.textalign || "left"};
`

export default TextImage

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirm-reschedule-consultation-js": () => import("./../../../src/pages/confirm-reschedule-consultation.js" /* webpackChunkName: "component---src-pages-confirm-reschedule-consultation-js" */),
  "component---src-pages-manage-consultation-js": () => import("./../../../src/pages/manage-consultation.js" /* webpackChunkName: "component---src-pages-manage-consultation-js" */),
  "component---src-pages-online-consultation-js": () => import("./../../../src/pages/online-consultation.js" /* webpackChunkName: "component---src-pages-online-consultation-js" */),
  "component---src-pages-reschedule-consultation-js": () => import("./../../../src/pages/reschedule-consultation.js" /* webpackChunkName: "component---src-pages-reschedule-consultation-js" */),
  "component---src-pages-typography-js": () => import("./../../../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */),
  "component---src-templates-archive-category-js": () => import("./../../../src/templates/archive/category.js" /* webpackChunkName: "component---src-templates-archive-category-js" */),
  "component---src-templates-archive-posts-js": () => import("./../../../src/templates/archive/posts.js" /* webpackChunkName: "component---src-templates-archive-posts-js" */),
  "component---src-templates-book-consultation-js": () => import("./../../../src/templates/book-consultation.js" /* webpackChunkName: "component---src-templates-book-consultation-js" */),
  "component---src-templates-confirm-consultation-js": () => import("./../../../src/templates/confirm-consultation.js" /* webpackChunkName: "component---src-templates-confirm-consultation-js" */),
  "component---src-templates-consultant-js": () => import("./../../../src/templates/consultant.js" /* webpackChunkName: "component---src-templates-consultant-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single/page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}


import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Edges, BackgroundImage, Breadcrumbs, Button, Spacer } from "components"

const PageHeader = props => {
  const {
    backgroundColor,
    breadcrumbs,
    button,
    headline,
    image,
    pt,
    pb,
    settings,
    subHeading,
    textColor,
    backgroundColorContainer,
    uri
  } = props

  const { link, ...buttonRest } = button || {}

  return (
    <Container backgroundColor={backgroundColorContainer}>
      <Edges size={settings?.contentLayout} relative>
        <Content
          backgroundColor={backgroundColor}
          textColor={textColor}
          textAlign={settings?.textAlign}
        >
          <Spacer pt={pt} pb={pb}>
            <div>
              {headline && (
                <Spacer mb={20}>
                  <Typography
                    color="inherit"
                    variant={headline.variant || "h1"}
                    children={headline.text}
                  />
                </Spacer>
              )}
              {subHeading && (
                <Spacer mb={20}>
                  <Typography color="inherit" children={subHeading} />
                </Spacer>
              )}
              {link && (
                <Spacer mb={20}>
                  <Button {...link} {...buttonRest} />
                </Spacer>
              )}
              {breadcrumbs && <Breadcrumbs color={textColor} url={uri} />}
            </div>
          </Spacer>
        </Content>
      </Edges>

      {image && image.image && <BackgroundImage image={image} />}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 30px 0;
  background-color: ${props => props.backgroundColor || "transparent"};

  @media (min-width: 768px) {
    padding: 0;
  }
`

const Content = styled(({ backgroundColor, textColor, textAlign, ...rest }) => (
  <div {...rest} />
))`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: ${props => (props.backgroundColor ? "400px" : "unset")};
  z-index: 5;
  padding: ${props => (props.backgroundColor ? "0 15px" : "0")};
  display: flex;
  align-items: center;
  background-color: ${props => props.backgroundColor || "transparent"};
  color: ${props => props.textColor || "#fff"};
  text-align: ${props => props.textAlign};

  @media (min-width: 768px) {
    padding: ${props => (props.backgroundColor ? "0 30px" : "0")};
  }
`

export default PageHeader

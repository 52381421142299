import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import ReactPlayer from "react-player"

// import app components
import { Edges, Headline, Spacer } from "components"
import { colors } from "theme"

const Embeds = props => {
  const {
    backgroundColor,
    headline,
    items,
    itemsPerRow,
    subHeading,
    settings,
    textColor,
    pt,
    pb
  } = props

  const itemCount = items ? items.length : null

  return (
    <Container backgroundColor={backgroundColor} textColor={textColor}>
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings.contentLayout}>
          {headline.text && (
            <Headline
              children={headline.text}
              color={textColor}
              textAlign={settings?.textAlign}
              underline={true}
              variant={headline.variant}
            />
          )}

          {subHeading && (
            <>
              <SubHeading textalign={settings?.textAlign}>
                <Typography children={subHeading} />
              </SubHeading>
              <Spacer mb={45} />
            </>
          )}
          <EmbedContainer>
            {items &&
              items.map((o, i) => {
                return (
                  <Item key={i} itemsperrow={itemsPerRow} itemCount={itemCount}>
                    {o.title && (
                      <Spacer mb={20}>
                        <Headline
                          children={o.title}
                          color={textColor}
                          textAlign={settings?.textAlign}
                          variant="h4"
                        />
                      </Spacer>
                    )}
                    <ResponsiveEmbed
                      itemsperrow={itemsPerRow}
                      mediatype={o.mediaType}
                      className={
                        o.mediaType === "video"
                          ? "responsive-video"
                          : "responsive-podcast"
                      }
                    >
                      {o.mediaType === "video" ? (
                        <ReactPlayer height="auto" url={o.media} width="100%" />
                      ) : (
                        <iframe
                          allow="encrypted-media"
                          allowtransparency="true"
                          frameBorder="0"
                          src={o.media}
                          title=""
                        ></iframe>
                      )}
                    </ResponsiveEmbed>
                  </Item>
                )
              })}
          </EmbedContainer>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled(({ backgroundColor, textColor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundColor || "transparent"};
  color: ${props => props.textColor || colors.alt};
`

const SubHeading = styled(({ textalign, ...rest }) => <div {...rest} />)`
  text-align: ${props => props.textalign};
  margin: 0 auto;
`

const EmbedContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`

const Item = styled(({ itemsperrow, itemCount, ...rest }) => <div {...rest} />)`
  margin: 0 0 30px;
  flex-basis: 100%;

  @media (min-width: 1200px) {
    margin-bottom: ${props =>
      props.itemCount > props.itemsperrow ? "30px" : "0px"};
    flex-basis: ${props =>
      props.itemsperrow === 2
        ? "calc(50% - 30px)"
        : props =>
            props.itemsperrow === 3
              ? "calc(33% - 30px)"
              : props =>
                  props.itemsperrow === 4
                    ? "calc(25% - 30px)"
                    : props => (props.itemsperrow === 5 ? "20%" : "100%")};
  }
`

const ResponsiveEmbed = styled(({ mediatype, itemsperrow, ...rest }) => (
  <div {...rest} />
))`
  height: 0;
  position: relative;

  &.responsive-video {
    padding-bottom: 56.25%;
  }

  &.responsive-podcast {
    padding-bottom: 50%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1200px) {
    &.responsive-video {
      padding-bottom: 56.25%;
    }

    &.responsive-podcast {
      padding-bottom: ${props =>
        props.itemsperrow === 2
          ? "31.5"
          : props => (props.itemsperrow === 3 ? "50%" : "23.5%")};
    }
  }
`

export default Embeds

import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"

// import app components
import { Edges, Headline, Spacer, Textarea } from "components"
import { getIcon } from "utils"

const Faq = props => {
  const {
    backgroundColor,
    items,
    headline,
    pt,
    pb,
    subHeading,
    settings,
    textColor
  } = props

  return (
    <Container
      backgroundcolor={backgroundColor}
      textcolor={textColor}
    >
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings.contentLayout}>
          {headline.text && (
            <Headline
              underline={true}
              textAlign={settings?.textAlign}
              variant={headline.variant}
              children={headline.text}
            />
          )}
          {subHeading && (
            <SubHeading
              textalign={settings?.textAlign}
            >
              <Typography
                children={subHeading}
              />
            </SubHeading>
          )}
          <Spacer mt={40} />
          <AccordionContainer>
            {items && (
              <div>
                {items.map((item, i) => {
                  return (
                    <AccordionItem key={i}>
                      <AccordionQuestion
                        expandIcon={getIcon("ChevronDown")}
                        aria-controls={`panel${i}a-content`}
                        id={`panel${i}a-header`}
                      >
                        {item.customIcon ? (
                          <IconCont>
                            <img
                              alt={item.customIcon.altText}
                              src={item.customIcon.sourceUrl}
                            />
                          </IconCont>
                        ) : (
                          item.icon && <IconCont>{getIcon(item.icon)}</IconCont>
                        )}

                        <Spacer mr={15} />
                        <Typography variant="h5">{item.question}</Typography>
                      </AccordionQuestion>
                      <AccordionAnswer>
                        {/* <Typography> */}
                          <Textarea content={item.answer} />
                        {/* </Typography> */}
                      </AccordionAnswer>
                    </AccordionItem>
                  )
                })}
              </div>
            )}
          </AccordionContainer>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled(({ backgroundcolor, textcolor, textalign, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundcolor};
  color: ${props => props.textcolor};
  margin: 0 auto;
  text-align: ${props => props.textalign ? props.textalign : "left"};
`

const SubHeading = styled(({ textalign, ...rest }) => <div {...rest} />)`
  text-align: ${props => props.textalign};
  margin: 0 auto;
`

const AccordionContainer = styled(({ ...rest }) => <div {...rest} />)`
  margin-bottom: 40px;
`

const AccordionItem = styled(Accordion)`
  border: 1px solid #e5e7e9;
  background: white;
  margin-bottom: 15px;
  border-radius: 5px;

  &:before {
    top: 0px;
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
`

const IconCont = styled.div`
  width: 40px;
  height: 40px;

  svg {
    width: 100%;
    height: 100%;
  }

  img {
    height: auto;
    max-width: 100%;
  }
`

const AccordionQuestion = styled(AccordionSummary)`
  background: #e5e7e9;

  .MuiIconButton-label svg path {
    fill: currentColor;
  }

  &.Mui-expanded {
    color: #f30127;

    .MuiTypography-root {
      color: #f30127;
    }

    .MuiIconButton-label {
      color: #f30127;
    }
  }

  > div {
    align-items: center;
  }
`

const AccordionAnswer = styled(AccordionDetails)`
  padding: 20px;
`

export default Faq

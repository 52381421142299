import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import Parser from "html-react-parser"
import { Button as MuiButton, CircularProgress } from "@material-ui/core"

import { colorLuminance } from "utils"
import { useStore } from "store"

export default ({
  backgroundColor,
  children,
  disabled,
  disableLightbox,
  icon,
  iconRotate,
  iconColor,
  textColor,
  loading,
  title,
  type,
  url,
  ...rest
}) => {
  const [, dispatch] = useStore()

  // Fixes bug where front page url ('/') is returning WP url
  const fixedUrl = url && url.replace(process.env.GATSBY_WP, "")

  const element = (
    <Button
      {...rest}
      backgroundColor={backgroundColor}
      disabled={disabled}
      loading={loading ? 1 : 0}
      textColor={textColor}
      type={type}
      variant={type}
    >
      <CircularProgress className="btn-loader" size={25} />
      {icon ? (
        <IconCont iconColor={iconColor} rotate={iconRotate}>
          {icon}
        </IconCont>
      ) : null}
      <span className="btn-children">
        {children ? children : title && Parser(title)}
      </span>
    </Button>
  )

  if (url) {
    if (disabled) {
      return element
    } else if (
      (url.includes("youtu.be") || url.includes("youtube.com")) &&
      !url.includes("https://www.youtube.com/channel/") &&
      !disableLightbox
    ) {
      return (
        <div
          onClick={() =>
            dispatch({
              type: "SET_LIGHTBOX",
              payload: {
                open: true,
                slide: 0,
                slides: [{ link: { url } }],
                options: {
                  thumbs: false
                }
              }
            })
          }
        >
          {element}
        </div>
      )
    } else if (fixedUrl.includes("wp-content/uploads/")) {
      return (
        <a
          href={`${process.env.GATSBY_WP}${fixedUrl}`}
          target="_blank"
          rel="noreferrer"
        >
          {element}
        </a>
      )
    } else if (fixedUrl.includes("http")) {
      return (
        <a href={fixedUrl} target="_blank" rel="noreferrer">
          {element}
        </a>
      )
    } else if (fixedUrl.includes("tel:") || fixedUrl.includes("mailto:")) {
      return <a href={fixedUrl}>{element}</a>
    } else {
      return <Link to={fixedUrl}>{element}</Link>
    }
  } else {
    return element
  }
}

const Button = styled(
  ({ backgroundColor, textColor, loading, variant, ...rest }) => (
    <MuiButton {...rest} />
  )
)`
  && {
    position: relative;
    color: ${props => props.textColor};
    border: 2px solid ${props => props.backgroundColor};

    ${({ variant, backgroundColor }) =>
      variant === "outlined"
        ? css`
            background-color: transparent;
          `
        : css`
            background-color: ${backgroundColor};
          `}

    &:hover {
      ${({ variant, backgroundColor, textColor }) =>
        variant === "outlined"
          ? css`
              color: ${colorLuminance(textColor, 0.5)};
              border-color: ${colorLuminance(backgroundColor, 0.5)};
            `
          : css`
              background-color: ${props => colorLuminance(backgroundColor)};
            `}
    }

    .btn-loader {
      position: absolute;
      opacity: 0;
      z-index: 1;
      pointer-events: none;
      left: calc(50% - 12.5px);
    }
    .btn-children {
      display: flex;
      align-items: center;
      > svg {
        margin-left: 10px;
      }
    }

    ${({ loading }) =>
      loading &&
      css`
        .btn-children {
          opacity: 0;
        }
        .btn-loader {
          opacity: 1;
        }
      `}
  }
`

const IconCont = styled(({ rotate, iconColor, ...rest }) => <span {...rest} />)`
  color: ${props => props.iconColor};
  margin-right: 10px;
  transform: ${props => (props.rotate ? "rotate(180deg)" : "none")};
`

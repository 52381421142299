import React from "react"

import ArrivalIcon from "icons/arrival.svg"
import ApplicationIcon from "icons/application.svg"
import ArrowRightIcon from "icons/arrow-right.svg"
import BubblesIcon from "icons/bubbles.svg"
import BookingIcon from "icons/booking.svg"
import BurgerCrossedIcon from "icons/burger-crossed.svg"
import BookIcon from "icons/book.svg"
import CanadaIcon from "icons/canada.svg"
import CabIcon from "icons/cab.svg"
import CalculatorIcon from "icons/calculator.svg"
import ConsultationIcon from "icons/consultation.svg"
import ClipboardIcon from "icons/clipboard.svg"
import ChevronDown from "icons/chevron-down2.svg"
import DocumentIcon from "icons/document.svg"
import EntryIcon from "icons/entry.svg"
import EnvelopIcon from "icons/envelop.svg"
import ExitIcon from "icons/exit.svg"
import FinanceIcon from "icons/finance.svg"
import HouseIcon from "icons/house.svg"
import HatIcon from "icons/hat.svg"
import ImmigrateIcon from "icons/immigrate.svg"
import MicrophoneIcon from "icons/microphone.svg"
import NoteIcon from "icons/note.svg"
import NewspaperIcon from "icons/newspaper.svg"
import PeopleIcon from "icons/people.svg"
import PersonIcon from "icons/person.svg"
import PlusIcon from "icons/plus.svg"
import PenIcon from "icons/pen.svg"
import StarIcon from "icons/star.svg"
import StudyIcon from "icons/study.svg"
import ToolsIcon from "icons/tools.svg"
import PercentageIcon from "icons/percentage.svg"
import UmbrellaIcon from "icons/umbrella.svg"
import VisitIcon from "icons/visit.svg"
import WorkIcon from "icons/work.svg"
import WorkshopIcon from "icons/workshop.svg"

const getIcon = name => {
  switch (name) {
    case "Arrival":
      return <ArrivalIcon />

    case "Application":
      return <ApplicationIcon />

    case "ArrowRight":
      return <ArrowRightIcon />

    case "Bubbles":
      return <BubblesIcon />

    case "Booking":
      return <BookingIcon />

    case "Cab":
      return <CabIcon />

    case "Calculator":
      return <CalculatorIcon />

    case "Canada":
      return <CanadaIcon />

    case "ChevronDown":
      return <ChevronDown />

    case "Consultation":
      return <ConsultationIcon />

    case "Clipboard":
      return <ClipboardIcon />

    case "Document":
      return <DocumentIcon />

    case "Entry":
      return <EntryIcon />

    case "Envelop":
      return <EnvelopIcon />

    case "Exit":
      return <ExitIcon />

    case "Hat":
      return <HatIcon />

    case "People":
      return <PeopleIcon />
    
    case "Immigrate":
      return <ImmigrateIcon />

    case "Person":
      return <PersonIcon />

    case "Pen":
      return <PenIcon />

    case "Note":
      return <NoteIcon />

    case "BurgerCrossed":
      return <BurgerCrossedIcon />

    case "Book":
      return <BookIcon />

    case "Workshop":
      return <WorkshopIcon />

    case "Newspaper":
      return <NewspaperIcon />

    case "Microphone":
      return <MicrophoneIcon />

    case "Finance":
      return <FinanceIcon />

    case "Percentage":
      return <PercentageIcon />

    case "House":
      return <HouseIcon />
    
    case "Visit":
      return <VisitIcon />

    case "Plus":
      return <PlusIcon />

    case "Study":
      return <StudyIcon />

    case "Star":
      return <StarIcon />

    case "Tools":
      return <ToolsIcon />

    case "Umbrella":
      return <UmbrellaIcon />

    case "Work":
      return <WorkIcon />

    default:
  }
}

export default getIcon

import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { Typography } from "@material-ui/core"

import slickStyles from "../../theme/slickStyles"

// import app components
import { Edges, Headline, Spacer } from "components"
import { getIcon } from "utils"
import { colors } from "theme"

const Testimonials = props => {
  const {
    backgroundColor,
    headline,
    pt,
    pb,
    subHeading,
    settings,
    textColor,
    testimonial
  } = props

  const NextArrow = props => {
    const { className, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        {getIcon("ChevronDown")}
      </div>
    )
  }

  const PrevArrow = props => {
    const { className, onClick } = props
    return (
      <div className={className} onClick={onClick}>
        {getIcon("ChevronDown")}
      </div>
    )
  }

  const sliderSettings = {
    arrows: true,
    dots: true,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  }

  return (
    <Container
      backgroundcolor={backgroundColor}
      textcolor={textColor}
    >
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings.contentLayout}>
          {headline?.text && (
            <Headline
              children={headline.text}
              color={textColor}
              textAlign={settings?.textAlign}
              underline={true}
              variant={headline.variant}
            />
          )}
          <SubHeading>
            {subHeading && <Typography children={subHeading} />}
          </SubHeading>
          <Flex>
            <Edges size="md">
              <Slider {...sliderSettings}>
                {testimonial &&
                  testimonial.map((o, i) => {
                    return (
                      <Item key={i}>
                        {o.name && <Headline variant="h4" children={o.name} />}
                        <li>
                          <Stars>
                            {Array.apply(null, { length: o.starRating }).map(
                              (e, i) => (
                                <Star key={i}>{getIcon("Star")}</Star>
                              )
                            )}
                          </Stars>
                        </li>
                        {o.date && (
                          <Date textColor={textColor} children={o.date} />
                        )}
                        {o.quote && <Quote children={o.quote} />}
                      </Item>
                    )
                  })}
              </Slider>
            </Edges>
          </Flex>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled(({ backgroundcolor, textcolor, ...rest }) => (
  <div {...rest} />
))`
  ${slickStyles}

  background: ${props => props.backgroundcolor || "transparent"};
  color: ${props => props.textcolor || colors.text.primary};

  * {
    outline: none;
  }

  .slick-dots {
    bottom: -50px;

    li button:before {
      font-size: 35px;
      opacity: 0.2;
    }

    li.slick-active button:before {
      color: ${colors.secondary};
      opacity: 1;
    }
  }

  .slick-prev {
    left: -50px;

    @media (min-width: 1024px) {
      left: -80px;
    }
  }

  .slick-next {
    right: -50px;

    @media (min-width: 1024px) {
      right: -80px;
    }
  }

  .slick-prev,
  .slick-next {
    display: none;

    @media (min-width: 768px) {
      color: ${colors.alt};
      display: flex;
      height: 40px;
      top: calc(50% - 20px);
      width: 40px;
    }

    @media (min-width: 1024px) {
      height: 65px;
      top: calc(50% - 32.5px);
      width: 65px;
    }
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    background: ${colors.secondary};
    color: white;
  }
`

const SubHeading = styled.div`
  text-align: center;
  margin: 0 auto 40px;
`

const SlickContainer = styled.div``

const Item = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Quote = styled.li`
  margin-top: 15px;
`

const Date = styled.li`
  color: ${props => props.textColor || "#84868B"};
  font-size: 20px;
  line-height: 30px;
`

const Stars = styled.div`
  display: flex;
  margin-bottom: 5px;
`

const Star = styled.span`
  height: 21px;
`

const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

export default Testimonials

import React from "react"
import { overrideTailwindClasses } from "tailwind-override"

export const Header1 = ({ children, className = "" }) => {
  return (
    <h1
      className={overrideTailwindClasses(
        `font-bold font-sans text-ca-gray-700 mb-4 text-4xl ${className}`
      )}
    >
      {children}
    </h1>
  )
}

export const Header2 = ({ children, className = "" }) => {
  return (
    <h2
      className={overrideTailwindClasses(
        `font-bold font-sans text-ca-gray-600 mb-4 text-2xl ${className}`
      )}
    >
      {children}
    </h2>
  )
}

export const Header3 = ({ children, className = "" }) => {
  return (
    <h3
      className={overrideTailwindClasses(
        `font-bold font-sans text-ca-gray-600 mb-4 text-xl ${className}`
      )}
    >
      {children}
    </h3>
  )
}

export const Header4 = ({ children, className = "" }) => {
  return (
    <h4
      className={overrideTailwindClasses(
        `font-bold font-sans text-ca-gray-600 mb-4 text-lg ${className}`
      )}
    >
      {children}
    </h4>
  )
}

export const Header5 = ({ children, className = "" }) => {
  return (
    <h5
      className={overrideTailwindClasses(
        `font-bold font-sans text-ca-gray-600 mb-4 text-base ${className}`
      )}
    >
      {children}
    </h5>
  )
}

export const Header6 = ({ children, className = "" }) => {
  return (
    <h6
      className={overrideTailwindClasses(
        `font-bold font-sans text-ca-gray-600 mb-4 text-sm ${className}`
      )}
    >
      {children}
    </h6>
  )
}

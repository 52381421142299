import produce from "immer"

export const consultationState = {
  bookingId: null,
  expiryDate: null,
  confirmationNumber: null,
  variantId: null,
  serviceId: null,
  resource: {
    id: null,
    name: null,
    slug: null,
    imageUrl: null
  },
  time: {
    start: null,
    end: null
  },
  customer: {
    firstName: "",
    lastName: "",
    email: "",
    phone: ""
  },
  timezone: null
}

export const consultationReducer = (state, action) => {
  const { payload } = action

  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_BOOKING_ID":
        draft.bookingId = payload
        break

      case "SET_CONFIRMATION_NUMBER":
        draft.confirmationNumber = payload
        break

      case "SET_SERVICE_ID":
        draft.serviceId = payload
        break

      case "SET_RESOURCE":
        draft.resource = payload
        break

      case "SET_VARIANT_ID":
        draft.variantId = payload
        break

      case "SET_EXPIRY_DATE":
        draft.expiryDate = payload
        break

      case "SET_TIME":
        draft.time = payload
        break

      case "SET_TIMEZONE":
        draft.timezone = payload
        break

      case "SET_CUSTOMER":
        draft.customer = payload
        break

      default:
    }
  })
}

import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import Parser from "html-react-parser"

// import app components
import { Edges, Headline, Spacer } from "components"
import { colors } from "theme"

const StyledTable = props => {
  const {
    backgroundColor,
    footerText,
    headline,
    pt,
    pb,
    settings,
    subHeading,
    textColor,
    tableRow
  } = props

  const formatValue = value => {
    if (value === "'0'" || value === '"0"') {
      return 0
    } else if (!value) {
      return ""
    } else {
      return Parser(value)
    }
  }

  return (
    <Container backgroundcolor={backgroundColor} textcolor={textColor}>
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings?.contentLayout}>
          {headline?.text && (
            <Spacer mb={30}>
              <Headline
                children={Parser(headline.text || "")}
                color={textColor}
                textAlign={settings?.textAlign}
                variant={headline.variant}
              />
            </Spacer>
          )}
          {subHeading && (
            <Spacer mb={30}>
              <SubHeading textalign={settings?.textAlign}>
                <Typography children={subHeading} />
              </SubHeading>
            </Spacer>
          )}
          <Flex>
            <TableContainer>
              {tableRow &&
                tableRow.map((o, i) => {
                  return (
                    <Row
                      key={i}
                      textcolor={o.rowStyling.textColor}
                      bold={o.rowStyling.bold}
                      backgroundcolor={o.rowStyling.backgroundColor}
                    >
                      <Column backgroundcolor={o.rowStyling.backgroundColor}>
                        {formatValue(o.leftColumn)}
                      </Column>
                      <Column backgroundcolor={o.rowStyling.backgroundColor}>
                        {formatValue(o.rightColumn)}
                      </Column>

                      {(!!formatValue(o.column3) ||
                        formatValue(o.column3) === 0) && (
                        <Column backgroundcolor={o.rowStyling.backgroundColor}>
                          {formatValue(o.column3)}
                        </Column>
                      )}
                    </Row>
                  )
                })}
            </TableContainer>
          </Flex>
          {footerText && (
            <FooterText textAlign={settings?.textAlign}>
              {Parser(footerText)}
            </FooterText>
          )}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled(({ backgroundcolor, textcolor, ...rest }) => (
  <div {...rest} />
))`
  background: ${props => props.backgroundcolor || "transparent"};
  color: ${props => props.textcolor || colors.text.primary};
`

const TableContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  flex-basis: 100%;
  overflow: hidden;
`

const Column = styled(({ backgroundcolor, ...rest }) => <div {...rest} />)`
  border-right: 1px solid;
  border-color: ${props => props.backgroundcolor || "#ddd"};
  flex-basis: 50%;
  padding: 15px;

  &:last-child {
    border-right: none;
  }
`

const Row = styled(({ textcolor, backgroundcolor, bold, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundcolor};
  border-bottom: 1px solid;
  border-color: ${props => props.backgroundColor || "#ddd"};
  color: ${props => props.textcolor || colors.primary};
  display: flex;
  font-weight: ${props => (props.bold ? "bold" : "inherit")};

  &:last-child {
    border-bottom: none;
  }
`

const SubHeading = styled(({ textalign, ...rest }) => <div {...rest} />)`
  text-align: ${props => props.textalign || "left"};
`

const Flex = styled.div`
  display: flex;
`

const FooterText = styled(({ textAlign, ...rest }) => <div {...rest} />)`
  text-align: ${props => props.textAlign};
  margin-top: 30px;
`

export default StyledTable

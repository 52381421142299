import React from "react"
import styled from "styled-components"
import { IconButton } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import FacebookIcon from "icons/facebook.svg"
import TwitterIcon from "icons/twitter.svg"
import InstagramIcon from "icons/instagram.svg"
import YoutubeIcon from "icons/youtube.svg"
import SpotifyIcon from "icons/spotify.svg"
import AnchorIcon from "icons/anchor.svg"
import LinkedinIcon from "icons/linkedin.svg"
import PinterestIcon from "icons/pinterest.svg"

const Socials = props => {
  const { ...rest } = props

  const {
    themeOptions: {
      siteOptions: {
        socialMedia: {
          facebook,
          instagram,
          twitter,
          youtube,
          spotify,
          anchor,
          linkedin,
          pinterest
        }
      }
    }
  } = useOptionsQuery()

  const icons = [
    {
      link: facebook,
      label: "Facebook",
      icon: <FacebookIcon />
    },
    {
      link: instagram,
      label: "Instagram",
      icon: <InstagramIcon />
    },
    {
      link: youtube,
      label: "Youtube",
      icon: <YoutubeIcon />
    },
    {
      link: spotify,
      label: "Spotify",
      icon: <SpotifyIcon />
    },
    {
      link: twitter,
      label: "Twitter",
      icon: <TwitterIcon />
    },
    {
      link: pinterest,
      label: "Pinterest",
      icon: <PinterestIcon />
    },
    {
      link: anchor,
      label: "Anchor",
      icon: <AnchorIcon />
    },
    {
      link: linkedin,
      label: "LinkedIn",
      icon: <LinkedinIcon />
    }
  ]

  return (
    <Container {...rest}>
      {icons.map(o => {
        return (
          o.link &&
          o.label &&
          o.icon && (
            <Link
              key={o.label}
              href={o.link}
              target="_blank"
              rel="noreferrer nofollow"
              aria-label={o.label}
            >
              <IconButton>{o.icon}</IconButton>
            </Link>
          )
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
`

const Link = styled.a`
  && {
    display: flex;
    text-decoration: none;
    margin: 0 2px;

    button {
      padding: 2px;
    }

    svg {
      width: 16px !important;
      height: 16px !important;

      path {
        fill: #fff;
      }
    }
  }
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          themeOptions {
            siteOptions {
              socialMedia {
                facebook
                instagram
                twitter
                youtube
                spotify
                anchor
                linkedin
                pinterest
              }
            }
          }
        }
      }
    `
  )
  return wp
}

export default Socials

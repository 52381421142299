import React, { createContext, useContext, useReducer } from "react"

import { appState, appReducer } from "./appState"
import { consultationState, consultationReducer } from "./consultationState"

export const StateContext = createContext({})

export const StoreProvider = (props) => {
  const initialState = {
    appState,
    consultationState
  }

  const reducer = ({ appState, consultationState }, action) => ({
    appState: appReducer(appState, action),
    consultationState: consultationReducer(consultationState, action)
  })

  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {props.children}
    </StateContext.Provider>
  )
}

export const useStore = () => useContext(StateContext)

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import dateFormat from "dateformat"

import Fallback from "components/post/fallback.jpg"

// import app components
import { BackgroundImage, Button, Textarea } from "components"
import { colors } from "theme"
import { Header2 } from "components/typography/headers"

const NewsPost = props => {
  const {
    author,
    categories,
    date,
    image,
    fallbackimage,
    headline,
    text,
    url,
    ...rest
  } = props

  const formattedDate = dateFormat(date, "mmmm d, yyyy")

  return (
    <div {...rest}>
      <div className="flex flex-col lg:flex-row flex-wrap rounded-lg overflow-hidden">
        <div className="bg-ca-gray-500 w-full lg:w-1/3 min-h-64">
          <Link to={url}>
            <ImageContainer>
              {image ? (
                <BackgroundImage image={{ image }} />
              ) : (
                <FallbackImageCont>
                  <img
                    width="432"
                    height="379"
                    alt="Placeholder"
                    src={Fallback}
                  />
                </FallbackImageCont>
              )}
            </ImageContainer>
          </Link>
        </div>

        <div className="bg-ca-gray-200 p-8 lg:w-2/3">
          <div className="text-sm mb-4">
            {categories &&
              categories.nodes.map((o, i) => {
                return (
                  <span key={i}>
                  <Link to={o.uri} className="text-ca-gray-900 hover:underline">{o.name}</Link>
                    {i < categories.nodes.length - 1 ? ", " : ""}
                </span>
                )
              })}
          </div>

          {headline && (
            <Link to={url}>
              <Header2 className="mb-2">{headline}</Header2>
            </Link>
          )}

          <div className="flex text-base text-sm">
            {author && (
              <div className="text-ca-red-600">
                {author.name
                  ? author.name
                  : author.nickname
                    ? author.nickname
                    : "Canada Abroad"}
              </div>
            )}
            <div className="mx-2">
              &#8226;
            </div>
            {date && <div className="text-ca-red-600">{formattedDate}</div>}
          </div>

          <div className="my-6">
            <Textarea
              className="text-base"
              stripTags={true}
              content={text}
            />
          </div>

          <Link to={url}>
            <ReadMore
              backgroundColor={colors.background.light}
              textColor={`#fff`}
              title={`Read Article`}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

const ImageContainer = styled.div`
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
`

const FallbackImageCont = styled.div`
    height: 100%;
    position: relative;
    width: 100%;

    img {
        height: 100%;
        object-fit: cover;
        position: absolute;
        width: 100%;
    }
`

const ReadMore = styled(Button)`
    font-size: 22px;
    line-height: 22px;
    margin-top: 30px;

  && {
    font-weight: bold;
  }
`

export default NewsPost

import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Edges, Spacer, Headline, Textarea, Button } from "components"
import { colors } from "theme"
import { getIcon } from "utils"

const Steps = props => {
  const {
    backgroundColor,
    callToAction,
    headline,
    pt,
    pb,
    settings,
    steps,
    textColor,
    text,
    button
  } = props

  const { link, ...buttonRest } = button || {}
  const { ctaLink, ...ctaButtonRest } = callToAction.button || {}

  return (
    <Container
      textcolor={textColor}
      backgroundcolor={backgroundColor}
    >
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings.contentLayout}>
          {headline.text && (
            <Headline
              underline={true}
              textAlign={settings?.textAlign}
              variant={headline.variant}
              children={headline.text}
            />
          )}

          {text && (
            <TypogCont textAlign={settings.textAlign}>
              <Typography color="inherit" children={text} />
            </TypogCont>
          )}
          <FlexWrap>
            {steps && (
              <StepsCont calltoaction={callToAction}>
                <Spacer mt={{ xs: 40, sm: 60, md: 90 }}>
                  {steps.map((o, i) => {
                    return (
                      <Step key={i} type={getIcon(o.icon) ? "icon" : "count"}>
                        <IconWrapper>
                          {o.icon ? (
                            <IconContainer>{getIcon(o.icon)}</IconContainer>
                          ) : (
                            <CountContainer>
                              <Typography
                                color="inherit"
                                variant="h3"
                                children={i + 1}
                              />
                            </CountContainer>
                          )}
                        </IconWrapper>
                        <StepContent>
                          {o.title && (
                            <Typography
                              variant="h4"
                              children={o.title}
                              gutterBottom
                            />
                          )}
                          {o.description && <Textarea content={o.description} />}
                        </StepContent>
                      </Step>
                    )
                  })}
                </Spacer>
              </StepsCont>
            )}
            {callToAction.content && (
              <CallToAction calltoaction={callToAction}>
                <Flex justify="center">
                  <Textarea content={callToAction.content} />
                  {callToAction.button?.link?.url && (
                    <Button
                      {...ctaButtonRest}
                      backgroundColor={callToAction.button.backgroundColor}
                      title={callToAction.button.link.title}
                      url={callToAction.button.link.url}
                      type="fluid"
                      textColor={callToAction.button.textColor}
                    />
                  )}
                </Flex>
              </CallToAction>
            )}
            {link && (
              <ButtonContainer>
                <Button {...link} {...buttonRest} />
              </ButtonContainer>
            )}
          </FlexWrap>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled(({ backgroundcolor, textcolor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundcolor || "transparent"};
  color: ${props => props.textcolor || colors.text.primary};
`

const Flex = styled(({ justify, ...rest }) => (
  <div {...rest} />
))`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => props.justify || "flex-start"};
`

const Step = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  min-height: 130px;
  overflow: hidden;

  @media (min-width: 600px) {
    flex-wrap: nowrap;

    ${({ type }) =>
      type === "count" &&
      `
    &:after {
      content: "";
      position: absolute;
      left: 50px;
      top: 100px;
      width: 2px;
      height: 100%;
      background: #2b3540;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
    `}
  }
`

const TypogCont = styled(({ backgroundColor, textAlign, ...rest }) => (
  <div {...rest} />
))`
  text-align: ${props => props.textAlign || 'left' };
`

const StepsCont = styled(({ calltoaction, ...rest }) => (
  <div {...rest} />
))`
  flex-basis: ${props => props.calltoaction.content ? "50%" : "100%"}
`

const FlexWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const IconWrapper = styled.div`
  height: 100%;
  width: 150px;
  margin-bottom: 40px;

  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`

const IconContainer = styled.div`
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(12.5px, 12.5px);

  svg {
    width: 75px;
    height: 75px;

    path {
      fill: ${colors.secondary};
    }
  }
`

const CountContainer = styled.div`
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${colors.secondary};
  transform: translate(12.5px, 12.5px);
  color: #fff;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-12.5px, -12.5px);
    width: 100px;
    height: 100px;
    border: 2px solid #2b3540;
    border-radius: 50%;
  }

  svg {
    path {
      fill: #fff;
    }
  }
`

const StepContent = styled.div`
  width: 100%;

  @media (min-width: 600px) {
    width: auto;
    flex: 1;
    align-self: center;
    padding-bottom: 40px;
  }
`

const CallToAction = styled(({ calltoaction, ...rest }) => (
  <div {...rest} />
))`
  flex-basis: ${props => props.calltoaction.content ? "50%" : "100%"};
  padding: 15px;
  display: flex;
  align-items: ${props =>
    props.calltoaction.verticalAlignment === "middle" ? "center" :
    props.calltoaction.verticalAlignment === "bottom" ? "flex-end" : "flex-start"
  };
  justify-content: center;
  margin-top: 30px;

  @media (min-width: 600px) {
    padding: 30px;
    margin-top: ${props => props.calltoaction.verticalAlignment !== "top" ? "60px" : "0px"};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  margin-top: 20px;
`

export default Steps

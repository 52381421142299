import { createGlobalStyle } from "styled-components"

import colors from "./colors"

export default createGlobalStyle`

  a {
    text-decoration: none;
    color: ${colors.secondary};
  }

  * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  .cookieButtonContainer {
    @media (max-width: 640px){
      width: 100% !important;
    }
  }

  .cookieButton {
    color: #fff !important;
    border: 2px solid ${colors.secondary} !important;
    background: transparent !important;
    padding: 5px 10px !important;
    font-size: 14px !important;
    font-family: "Montserrat" !important;
    border-radius: 4px !important;
    margin: 0 0 0 10px !important;
    line-height: 1 !important;
    font-weight: 400 !important;
    transition: ease all 0.2s !important;

    @media (max-width: 640px){
      margin: 10px 0 0 !important;
      width: 100% !important;
    }

    &:hover {
      background: ${colors.secondary} !important;
    }
  }

  .cookieContent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 !important;

    @media (max-width: 640px){
      a {
          width: 100%;
      }
    }

    p {
      font-size: 14px !important;
    }
  }
`

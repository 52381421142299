import React from "react"
import styled from "styled-components"
import { colors } from "theme"
import { Edges } from "components"

const Immigreat = (props) => {
  const { backgroundColor = colors.secondary, textColor = "#f0f0f1", immigreatLink = "https://canadaabroad.immigreat.io/embed/oinp", settings } = props
  const handleUrlParams = (immigreatLink) => {
    let urlParams = new URLSearchParams();

    if (typeof window !== 'undefined') {
      if (document.referrer) {
        urlParams.append('referral_url', encodeURIComponent(document.referrer));
      }
      if (window.location.href) {
        urlParams.append('current_url', encodeURIComponent(window.location.href));
      }
    }

    const finalUrl = `${immigreatLink}?${urlParams.toString()}`;

    return finalUrl;
  };


  return (
    <Container textColor={textColor} backgroundColor={backgroundColor}>
      <Edges size={settings.contentLayout}>
        <FormContainer>
          <iframe
            frameborder="0"
            id="iframewin"
            src={handleUrlParams(immigreatLink)}
            style={{
              height: "80vh",
              width: "100%",
            }}
          />
        </FormContainer>
      </Edges>
    </Container>
  )
}
export default Immigreat

const Container = styled(({ backgroundColor, textColor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${(props) => props.backgroundColor || "transparent"};
  color: ${(props) => props.textColor || "#fff"};
`

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: stretch;
`

import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"

// import app components
import { Edges, Spacer, BackgroundImage, Button } from "components"

const Banner = props => {
  const {
    settings,
    backgroundColor,
    textColor,
    text,
    buttons,
    headline,
    image,
    pt,
    pb
  } = props

  return (
    <Container backgroundColor={backgroundColor}>
      <Spacer pt={pt} pb={pb}>
        <Edges size={settings.contentLayout}>
          <Content textColor={textColor} textAlign={settings.textAlign}>
            <Spacer sb={text ? 10 : 0}>
              {headline && (
                <Typography
                  color="inherit"
                  variant={headline.variant}
                  children={headline.text}
                />
              )}

              {text && <Typography color="inherit" children={text} />}

              {buttons && (
                <Spacer mt={20}>
                  <Grid
                    container
                    justifyContent={
                      settings.textAlign === "left"
                        ? "flex-start"
                        : settings.textAlign === "center"
                        ? "center"
                        : "flex-end"
                    }
                  >
                    {buttons.map(({ button: { link, ...rest } }, i) => {
                      return (
                        link && (
                          <Spacer
                            key={i}
                            ml={i === 0 ? 0 : 10}
                            mr={i === buttons.length - 1 ? 0 : 10}
                            mt={10}
                            mb={10}
                          >
                            <Button {...link} {...rest} />
                          </Spacer>
                        )
                      )
                    })}
                  </Grid>
                </Spacer>
              )}
            </Spacer>
          </Content>
        </Edges>
      </Spacer>

      {image && image.image && <BackgroundImage image={image} />}
    </Container>
  )
}

const Container = styled(({ backgroundColor, ...rest }) => <div {...rest} />)`
  position: relative;
  width: 100%;
  background-color: ${props => props.backgroundColor || "transparent"};
`

const Content = styled(({ textColor, textAlign, ...rest }) => (
  <div {...rest} />
))`
  position: relative;
  width: 100%;
  z-index: 2;
  color: ${props => props.textColor || "#fff"};
  text-align: ${props => props.textAlign};
`

export default Banner

import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { Link } from "gatsby"

// import app components
import { BackgroundImage, Edges, Headline, Spacer } from "components"
import { colors } from "theme"

const LargeButtons = props => {
  const {
    backgroundColor,
    buttons,
    buttonsPerRow,
    headline,
    image,
    pt,
    pb,
    settings,
    subHeading,
    textColor
  } = props

  return (
    <Container
      image={image}
      backgroundcolor={backgroundColor}
      textcolor={textColor}
    >
      <Spacer pt={pt} pb={pb}>
        <Content>
          <Edges size={settings.contentLayout}>
            {headline.text && (
              <Headline
                children={headline.text}
                textAlign={settings?.textAlign}
                underline={true}
                variant={headline.variant}
                color="inherit"
              />
            )}
            <SubHeading textalign={settings?.textAlign}>
              {subHeading && (
                <Typography children={subHeading} color="inherit" />
              )}
            </SubHeading>
            <Spacer mt={45} />
            {buttons && (
              <ButtonCont>
                {buttons.map((o, i) => {
                  return (
                    <LargeButton
                      key={i}
                      buttoncount={buttons.length}
                      buttonsperrow={buttonsPerRow}
                      backgroundcolor={o?.backgroundColor}
                      textcolor={o?.textColor}
                    >
                      <Headline
                        variant="h5"
                        children={o.title}
                        color="inherit"
                      />
                      {o?.customIcon && (
                        <IconCont>
                          <img
                            alt={o.customIcon.altText}
                            src={o.customIcon.sourceUrl}
                          />
                        </IconCont>
                      )}
                      {o?.link && (
                        <CustomLink
                          target={o?.link?.target}
                          to={o?.link?.url}
                        ></CustomLink>
                      )}
                    </LargeButton>
                  )
                })}
              </ButtonCont>
            )}
          </Edges>
        </Content>
      </Spacer>
      {image && image.image && <BackgroundImage image={image} />}
    </Container>
  )
}

const Container = styled(({ backgroundcolor, textcolor, ...rest }) => (
  <div {...rest} />
))`
  background-color: ${props => props.backgroundcolor || "transparent"};
  color: ${props => props.textcolor || colors.text.primary};
  position: relative;
`

const SubHeading = styled(({ textalign, ...rest }) => <div {...rest} />)`
  text-align: ${props => (props.textalign ? props.textalign : "left")};
  margin: 0 auto;
`

const Content = styled.div`
  z-index: 5;
  position: relative;
`

const IconCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-left: 10px;
  width: 80px;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`

const CustomLink = styled(Link)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

const LargeButton = styled(
  ({ buttonsperrow, buttoncount, backgoroundcolor, textcolor, ...rest }) => (
    <button {...rest} />
  )
)`
  align-items: center;
  background-color: ${props => props.backgroundcolor || "#fff"};
  border: 3px solid ${props => props.backgroundcolor || colors.secondary};
  border-radius: 12px;
  color: ${props => props.textcolor || "#000"};
  display: flex;
  flex-basis: 100%;
  font-size: 30px;
  justify-content: space-between;
  margin: 0px 15px 20px;
  min-width: 200px;
  padding: 15px;
  position: relative;
  text-align: left;
  text-transform: uppercase;

  @media (min-width: 768px) {
    flex-basis: calc(50% - 30px);
  }

  @media (min-width: 1200px) {
    flex-basis: calc(${props =>
      `${100 / props.buttonsperrow}% - 30px)` || "33%"};
    margin-bottom: ${props =>
      props.buttonsperrow < props.buttoncount ? "20px" : "0px"};
  }
`

const ButtonCont = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 0px;
`

const SvgCont = styled.div`
  min-height: 50px;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    min-height: 155px;
  }
`

export default LargeButtons

import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { AppBar, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import { MobileMenu, Edges, Socials, HeaderSecondaryMenu } from "components"
import HeaderMenu from "./HeaderMenu"
import SearchBar from "./SearchBar"

import Logo from "icons/logo-dark.svg"
import PhoneIcon from "icons/phone.svg"
import WhatsappIcon from "icons/whatsapp.svg"

import { useStore } from "store"
import * as theme from "theme"

const menuBreakpoint = 1150

const Header = (props) => {
  const { path } = props

  const [
    _,  // eslint-disable-line no-unused-vars
    dispatch
  ] = useStore()

  const {
    themeOptions: {
      siteOptions: {
        contact: { phoneNumberCa, phoneNumberZa }
      }
    }
  } = useOptionsQuery()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path, dispatch])

  return (
    <div>
      <StyledAppBar position="static">

        <div className="bg-ca-gray-700 w-full mb-4 -z-10">
          <div className="container width-full max-w-screen-xl">
            <HeaderSecondaryMenu />
          </div>
        </div>
        <Edges size="lg">
          <nav className="flex justify-center items-center sm:justify-between">
            <CTAContainer>
              <Link to="/online-consultation">Book a Consultation</Link>
            </CTAContainer>

            <PhoneNumber>
              {phoneNumberZa && (
                <a href={`tel:${phoneNumberZa}`}>
                  <PhoneIcon />
                  <span className="phone-number">{phoneNumberZa}</span>
                  <span>(ZA)</span>
                </a>
              )}
            </PhoneNumber>

            <LogoContainer>
              <Link
                to="/"
                onClick={() => dispatch({ type: "SET_MENU", payload: false })}
              >
                <Logo />
              </Link>
            </LogoContainer>

            <PhoneNumber>
              {phoneNumberCa && (
                <a href={`tel:${phoneNumberCa}`}>
                  <WhatsappIcon />
                  <span className="phone-number">{phoneNumberCa}</span>
                  <span>(CA)</span>
                </a>
              )}
            </PhoneNumber>

            <SocialsContainer>
              <Socials />
            </SocialsContainer>
          </nav>
        </Edges>
      </StyledAppBar>

      <HeaderMenu />

      <SearchBarContainer>
        <Edges size="lg">
          <nav className="flex justify-center items-center sm:justify-between">
            <SearchBar />
          </nav>
        </Edges>
      </SearchBarContainer>

      <MobileMenu />
    </div>
  )
}

export default Header

const StyledAppBar = styled(AppBar)`
  && {
    background: ${theme.colors.primary};
    padding: 0 0 10px;
    display: flex;
    align-items: center;
    z-index: 999;
    position: relative;
  }
`

const LogoContainer = styled.div`
  display: none;

  @media (min-width: ${menuBreakpoint}px) {
    display: block;
  }

  a {
    text-decoration: none;
  }

  svg {
    width: 120px;
    height: 120px;
  }
`

const SocialsContainer = styled.div`
  display: none;

  @media (min-width: 420px) {
    display: block;
  }

  @media (min-width: ${menuBreakpoint}px) {
    width: 200px;
  }
`

const CTAContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 100%;
  a {
    color: #fff;
    font-size: 14px;
    letter-spacing: -0.5px;
    background-color: ${theme.colors.secondary};
    padding: 4px 12px;
    border-radius: 25px;
    font-weight: 500;
    border: 2px solid transparent;
    width: 100%;
    text-align: center;
    &:hover {
      border-color: #fff;
    }
  }

  @media (min-width: 420px) {
    width: 175px;
  }
`

const PhoneNumber = styled(Typography)`
  display: none;
  @media (min-width: 600px) {
    display: inline;
  }
  && {
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #fff;
    }

    .phone-number {
      display: none;

      @media (min-width: 960px) {
        display: inline-block;
        margin-right: 5px;
      }
    }

    svg {
      margin-right: 10px;
      width: 18px !important;
      height: 18px !important;

      path {
        fill: #fff;
      }
    }
  }
`

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  background: ${theme.colors.primary};
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          themeOptions {
            siteOptions {
              contact {
                phoneNumberCa
                phoneNumberZa
              }
            }
          }
        }
      }
    `
  )
  return wp
}

import { useState } from "react"
import createQueryString from "./createQueryString"
import fetch from "isomorphic-unfetch"

const initialState = {
  error: null,
  data: null,
  loading: false,
  called: false
}

export const useFetch = (endpoint, options = {}) => {
  const [state, setState] = useState(initialState)

  const callback = async args => {
    setState({
      ...state,
      called: true,
      loading: true,
      error: null
    })

    const params = args && createQueryString(args)

    await fetch(params ? endpoint + params : endpoint, {
      method: "get",
      // headers: { authorization: `Bearer ${token}` },
      ...options
    })
      .then(async response => {
        const json = await response.json()

        setState({
          ...state,
          loading: false,
          data: response.status === 200 ? json : null,
          called: true,
          error: response.status !== 200 ? json : null
        })
      })
      .catch(error => {
        setState({ ...state, error, loading: false, called: true })
      })
  }

  return [callback, state]
}

export default useFetch

import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { colors } from "theme"
import { Header3 } from "../typography/headers"

const Newsletter = (props) => {
  const { backgroundColor = colors.secondary, textColor = "#fff" } = props

  useEffect(() => {
    const script = document.createElement('script');
    script.src='https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '45842344',
          formId: '9580c5c5-6b2d-403b-b97f-420dc24110c8',
          target: '.hubspot-newsletter-form'
        })
      }
    });
  }, []);

  return (
    <div className="bg-ca-red-600 text-white py-10 flex justify-center items-center flex-col">
      <Header3 className="text-white">Subscribe to our newsletter</Header3>
      <div className="w-auto max-w-fit hubspot-newsletter-form">
      </div>
    </div>
  )
}

export default Newsletter

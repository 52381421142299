import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";

export default function BackgroundImage(props) {
  const {
    alt = "",
    image: { image, size, position },
    animated,
    overlay,
    ...rest
  } = props

  const getPercentage = string => {
    if (string === "left" || string === "top") {
      return "0%"
    } else if (string === "center") {
      return "50%"
    } else if (string === "right" || string === "bottom") {
      return "100%"
    }
  }

  const arr = position ? position.split("-") : ["center", "center"]
  const pos = [getPercentage(arr[0]), getPercentage(arr[1])]

  return (
    <Container {...rest} animated={animated} overlay={overlay} size={size}>
      <GatsbyImage
        alt={alt}
        image={image?.localFile?.childImageSharp?.gatsbyImageData ||
        image?.localFile?.childImageSharp?.gatsbyImageData}
        style={{
          objectFit: size || `cover`,
          objectPosition: `${pos[0]} ${pos[1]}`
        }}
        objectFit={size || `cover`}
        objectPosition={`${pos[0]} ${pos[1]}`} />
      {(image?.localFile?.childImageSharp?.gatsbyImageData ||
        image?.localFile?.childImageSharp?.gatsbyImageData) && (
        <GatsbyImage
          alt={alt}
          image={image?.localFile?.childImageSharp?.gatsbyImageData ||
          image?.localFile?.childImageSharp?.gatsbyImageData}
          style={{
            objectFit: size || `cover`,
            objectPosition: `${pos[0]} ${pos[1]}`
          }}
          objectFit={size || `cover`}
          objectPosition={`${pos[0]} ${pos[1]}`} />
      )}
    </Container>
  );
}

const Container = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      111.83deg,
      rgba(18, 23, 28, 0.59) 24.95%,
      rgba(18, 23, 28, 0) 75.92%
    );
    opacity: ${props => (props.overlay ? 1 : 0)};
  }

  .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
  }

  img {
    ${props =>
      props.size === "contain" &&
      `
      max-height: 100%;
      height: auto !important;
    `}
  }

  ${props =>
    props.animated &&
    `
    .gatsby-image-wrapper {
      transition: ease all 0.8s;
      transform: scale(1);
    }

    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.02);
      }
    }
  `}
`
